import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import WebsiteCacheCheck from './components/websiteCacheCheck/WebsiteCacheCheck';
import Spinner from './components/common/Spinner';

// Layout Blueprints

import { LeftSidebar } from './layout-blueprints';

/// Public Pages
const HomePage = lazy(() => import('./pages/Home'));
const DividendInfoPage = lazy(() => import('./pages/DividendInfo/DividendInfo'));
const DividendInfoHistoryPage = lazy(() => import('./pages/DividendInfo/DividendInfoHistoryPage'));
const StockInfoPage = lazy(() => import('./pages/stockInfo/StockInfo'));
const FeedBackFormPage = lazy(() => import('./pages/FeedBack/FeedBackForm'));

const CalculationsPage = lazy(() => import('./pages/Calculations/stockCalculations'));
const ShopPage = lazy(() => import('./pages/Shop/ShopPage'));
const AboutUsPage = lazy(() => import('./pages/AboutUs/index'));
const SignInPage = lazy(() => import('./pages/login/SignIn'));
const SignUpPage = lazy(() => import('./pages/login/SignUp'));
const ForgotPasswordPage = lazy(() => import('./pages/login/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('./pages/login/ResetPassword'));

///User pages
const DashboardPage = lazy(() => import('./pages/UserPages/dashboard/Dashboard'));
const UserDetailsPage = lazy(() => import('./pages/UserPages/userDetailsPage/UserDetailsPage'));
const UserGoalsPage = lazy(() => import('./pages/UserPages/goalsPage/GoalsPage'));
const DividendsUserCalendarPage = lazy(() => import('./pages/DividendInfo/DividendsUserCalendarPage'));

const MyStocksPage = lazy(() => import('./pages/UserPages/myStocksPage/MyStocksPage'));
const EditStockPage = lazy(() => import('./pages/UserPages/myStocksPage/EditStockPage'));
const AddNewStockPage = lazy(() => import('./pages/UserPages/myStocksPage/AddNewStockPage'));
const UserStockHistoryPage = lazy(() => import('./pages/UserPages/myStocksPage/StockHistoryPage'));

const AddWarrantPage = lazy(() => import('./pages/UserPages/myStocksPage/AddWarrantPage'));
const EditWarrantPage = lazy(() => import('./pages/UserPages/myStocksPage/EditWarrantPage'));
const BuyWarrantPage = lazy(() => import('./pages/UserPages/myStocksPage/BuyWarrantPage'));

/// reports
const StockOverviewPage = lazy(() => import('./pages/UserPages/stockOverviewPage/StockOverviewPage'));
const ProfilePerformancePage = lazy(() => import('./pages/UserPages/reportsPage/reports/profilePerformance/ProfilePerformance'));
const StockPerformancePage = lazy(() => import('./pages/UserPages/reportsPage/reports/reportStockPerformance/StockPerformance'));

// Crypto pages
const MyCryptoListPage = lazy(() => import('./pages/UserPages/cryptoPage/MyCryptoListPage'));
const EditCryptoPage = lazy(() => import('./pages/UserPages/cryptoPage/EditCryptoPage'));
const AddNewCryptoPage = lazy(() => import('./pages/UserPages/cryptoPage/AddNewCryptoPage'));


//const MyDividendsPage = lazy(() => import('./pages/UserPages/userDetailsPage/UserDetailsPage'));
const ReportsPage = lazy(() => import('./pages/UserPages/reportsPage/ReportsPage'));

// Admin pages
const MangeNewsPage = lazy(() => import('./pages/UserPages/userDetailsPage/UserDetailsPage'));
const MangeShopPage = lazy(() => import('./pages/UserPages/userDetailsPage/UserDetailsPage'));
const GlossaryPage = lazy(() => import('./pages/UserPages/userDetailsPage/UserDetailsPage'));
const AdminDashboardPage = lazy(() => import('./pages/UserPages/userDetailsPage/UserDetailsPage'));
const ManageUsersPage = lazy(() => import('./pages/UserPages/userDetailsPage/UserDetailsPage'));


const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return (<>
    <WebsiteCacheCheck />

    <AnimatePresence>
      <Suspense
        fallback={
          <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
            <div>
              <div className="w-150 mx-auto">
                Please wait while we load the page
              </div>
              <Spinner className="w-50 mx-auto" />
            </div>
          </div>
        }>
        {/*LandingPage*/}
        <Switch>
          <Redirect exact from="/" to="/Home" />
          {/*<Route
              path="/MyStocks/EditStock/:code/:market"
              component={EditStockPage}
            />*/}
          <Route
            path="/MyStocks/StockHistory/:code/:market"
            component={UserStockHistoryPage}
          />
          <Route
            path={[
              '/Dashboard',
              "/UserDetails",
              '/UserGoals',
              '/MyStocks/EditWarrant/:code/:market',
              '/MyStocks/BuyWarrant/:id/:code/:quantity',
              '/MyStocks/EditStock/:code/:market',
              '/MyStocks/StockHistory/:code/:market',
              '/MyStocks',
              '/AddNewStock',
              '/AddWarrant',
              '/MyDividends',
              '/Reports',
              '/Home',
              '/StockInfo/:code/:market',
              '/DividendInfo/History/:code/:market',
              '/DividendInfo',
              '/Calculations',
              '/Shop',
              '/AboutUs',
              '/Admin/Home/News',
              '/Admin/Home/Shop',
              '/Admin/Home/Glossary',
              '/Admin/Private/Dashboard',
              '/Admin/Private/Users',
              '/SignIn',
              '/SignUp',
              '/'
            ]}>
            <LeftSidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  {/* User accounts */}
                  <Route
                    path="/Dashboard"
                    component={DashboardPage}
                  />
                  <Route
                    path="/UserDetails"
                    component={UserDetailsPage}
                  />
                  <Route
                    path="/UserGoals"
                    component={UserGoalsPage}
                  />
                  <Route
                    path="/MyStocks/EditStock/:code/:market"
                    component={EditStockPage}
                  />
                  <Route
                    exact path="/MyStocks/AddNewStock/"
                    component={AddNewStockPage}
                  />
                  <Route
                    path="/MyStocks/StockHistory/:code/:market"
                    component={UserStockHistoryPage}
                  />
                  <Route
                    exact path="/MyStocks/AddWarrant/"
                    component={AddWarrantPage}
                  />
                  <Route
                    path="/MyStocks/EditWarrant/:code/:market"
                    component={EditWarrantPage}
                  />
                  <Route
                    path="/MyStocks/BuyWarrant/:id/:code/:quantity"
                    component={BuyWarrantPage}
                  />
                  <Route
                    exact path="/MyStocks"
                    component={MyStocksPage}
                  />
                  <Route
                    exact path="/Reports/MyStockOverview"
                    component={StockOverviewPage}
                  />
                  <Route
                    exact path="/Reports/PortfolioPerformance"
                    component={ProfilePerformancePage}
                  />

                  <Route
                    exact path="/Reports/MyStockPerformance"
                    component={StockPerformancePage}
                  />
                  <Route
                    path="/MyDividends"
                    component={DividendsUserCalendarPage}
                  />
                  <Route
                    path="/MyCrypto/Edit/:code"
                    component={EditCryptoPage}
                  />
                  <Route
                    exact path="/MyCrypto/addNew"
                    component={AddNewCryptoPage}
                  />
                  <Route
                    exact path="/MyCrypto"
                    component={MyCryptoListPage}
                  />
                  <Route
                    exact path="/Reports"
                    component={ReportsPage}
                  />
                  {/* Home / public Pages */}
                  <Route
                    path="/Home"
                    component={HomePage}
                  />
                  <Route
                    path="/FeedBackForm"
                    component={FeedBackFormPage}
                  />

                  <Route
                    exact path="/DividendInfo/History/:code/:market"
                    component={DividendInfoHistoryPage}
                  />
                  <Route
                    exact path="/StockInfo/:code/:market"
                    component={StockInfoPage}
                  />
                  <Route
                    exact path="/DividendInfo"
                    component={DividendInfoPage}
                  />

                  <Route
                    path="/Calculations"
                    component={CalculationsPage}
                  />
                  <Route
                    path="/Shop"
                    component={ShopPage}
                  />
                  <Route
                    path="/AboutUs"
                    component={AboutUsPage}
                  />
                  <Route
                    path="/SignIn"
                    component={SignInPage}
                  />
                  <Route
                    path="/SignUp"
                    component={SignUpPage}
                  />
                  <Route
                    path="/ForgotPassword"
                    component={ForgotPasswordPage}
                  />

                  <Route
                    exact path="/ResetPassword/:email/:token"
                    component={ResetPasswordPage}
                  />
                  <Route
                    exact path="/ResetPassword/:email"
                    component={ResetPasswordPage}
                  />
                  <Route
                    exact path="/ResetPassword/"
                    component={ResetPasswordPage}
                  />
                  {/* Admin Pages */}
                  <Route
                    path="/Admin/Home/News"
                    component={MangeNewsPage}
                  />
                  <Route
                    path="/Admin/Home/Shop"
                    component={MangeShopPage}
                  />
                  <Route
                    path="/Admin/Home/Glossary"
                    component={GlossaryPage}
                  />
                  <Route
                    path="/Admin/Private/Dashboard"
                    component={AdminDashboardPage}
                  />
                  <Route
                    path="/Admin/Private/Users"
                    component={ManageUsersPage}
                  />

                </motion.div>
              </Switch>
            </LeftSidebar>
          </Route>
        </Switch>
      </Suspense>
    </AnimatePresence>

  </>);
};

export default Routes;
