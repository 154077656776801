import { ActionTypes } from '../ActionTypes';
import rLensProp from 'ramda/src/lensProp';
import rSet from 'ramda/src/set';
//import rClone from 'ramda/src/clone';
/*import _get from 'lodash/get';
import _set from 'lodash/set';*/
export const websiteState = {
    isLoading: {},
    cacheDateTime: {},
    youtubeCards: [],
    countorycodes: [],
    nzxLatestPrice: [],
    asxLatestPrice: [],
    stockAnnouncements: [],
    timeFrames: [{ id: "1", name: 'Yearly' }, { id: "2", name: 'Monthly' }, { id: "3", name: 'Fortnightly' }, { id: "4", name: 'Weekly' }],
    isUserAuthentication: false,
    webPageTile: 'Home',
    allStockCodeNameMarket: [],
}

/*
https://levelup.gitconnected.com/react-redux-hooks-useselector-and-usedispatch-f7d8c7f75cdd
*/
const cacheDateTimeProp = rLensProp('cacheDateTime');
const isLoadingProp = rLensProp('isLoading');
const youtubeCardsProp = rLensProp('youtubeCards');
const countorycodesProp = rLensProp('countorycodes');
const nzxLatestPriceProp = rLensProp('nzxLatestPrice');
const asxLatestPriceProp = rLensProp('asxLatestPrice');
const timeFramesProp = rLensProp('timeFrames');
const isUserAuthenticationProp = rLensProp('isUserAuthentication');
const allStockCodeNameMarketProp = rLensProp('allStockCodeNameMarket');
const stockAnnouncementsProp = rLensProp('stockAnnouncements');
const webPageTileProp = rLensProp('webPageTile');

const websiteReducer = (state = websiteState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_CACHE:
            let payloadCache = state.cacheDateTime;
            var newDateTime = new Date();
            newDateTime.setHours(newDateTime.getHours() - 1);
            payloadCache[action.key] = newDateTime;
            return rSet(
                cacheDateTimeProp,
                payloadCache,
                state
            )
        case ActionTypes.RESET_CACHE:
            let payloadResetCache = state.cacheDateTime;
            if (!action || action?.key === '') {
                payloadResetCache = {};
            } else if (typeof action.key === "string" && (action.key === '' || action.key === "all")) {
                payloadResetCache = {};
            } else if (Array.isArray(action.key)) {
                action.key.forEach(key => {
                    if (payloadResetCache[key]) {//if it has not been set it will not be updated
                        payloadResetCache[key] = false;
                    }
                });
            } else if (typeof action.key === "string") {
                payloadResetCache[action.key] = false;
            }
            return rSet(
                cacheDateTimeProp,
                payloadResetCache,
                state
            )
        case ActionTypes.ASSIGN_IS_LOADING:
            let payload = state.isLoading;
            payload[action.key] = action.value;
            return rSet(
                isLoadingProp,
                payload,
                state
            )
        case ActionTypes.ASSIGN_WEBSITE_SET_STOCK_ANNOUNCEMENTS:
            return rSet(
                stockAnnouncementsProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_WEBSITE_TITLE:
            return rSet(
                webPageTileProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_WEBSITE_YOUTUBE_CONTEN:
            return rSet(
                youtubeCardsProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_WEBSITE_SET_ALL_STOCK_CODE_NAME_MARKET:
            return rSet(
                allStockCodeNameMarketProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_WEBSITE_IS_AUTHENTICATED:
            return rSet(
                isUserAuthenticationProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_WEBSITE_TIME_FRAMES:
            return rSet(
                timeFramesProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_WEBSITE_COUNTORY_CODES:
            return rSet(
                countorycodesProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_WEBSITE_NZX_LATEST_PRICE:
            return rSet(
                nzxLatestPriceProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_WEBSITE_ASX_LATEST_PRICE:
            return rSet(
                asxLatestPriceProp,
                action.payload,
                state
            )
        case ActionTypes.RESET_REDUX:
            return websiteState;
        default:
            return state
    }
}

export default websiteReducer;