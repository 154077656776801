import { ActionTypes } from '../ActionTypes';
import rLensProp from 'ramda/src/lensProp';
import rSet from 'ramda/src/set';

export const cryptoInitState = {
    cryptoList: null,
    cryptoMetaData: null,
    userCrypto: null,
    userCryptoPrices: null,
}
const cryptoListProp = rLensProp('cryptoList');
const cryptoMetaDataProp = rLensProp('cryptoMetaData');
const userCryptoProp = rLensProp('userCrypto');
const userCryptoPricesProp = rLensProp('userCrypto');

/*
https://levelup.gitconnected.com/react-redux-hooks-useselector-and-usedispatch-f7d8c7f75cdd
*/
const cryptoReducer = (state = cryptoInitState, action) => {
    switch (action.type) {
        case ActionTypes.ASSIGN_CRYPTO_LIST:
            return rSet(
                cryptoListProp,
                action.payload,
                state
            )
            case ActionTypes.ASSIGN_CRYPTO_META_DATA:
                return rSet(
                    cryptoMetaDataProp,
                    action.payload,
                    state
                )
        case ActionTypes.ASSIGN_CRYPTO_USER:
            return rSet(
                userCryptoProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_CRYPTO_CURRENT_PRICE:
            return rSet(
                userCryptoPricesProp,
                action.payload,
                state
            )


        default:
            return state;
    }
}

export default cryptoReducer;