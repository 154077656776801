export const accountItems = [
  {
    label: 'Account',
    content: [
      {
        label: "Login",
        description: "",
        to: "/SignIn"
      },
      {
        label: "Sign up",
        description: "",
        to: "/SignUp"
      },
    ]
  }
]

export default accountItems;
  