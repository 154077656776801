import { ActionTypes } from '../ActionTypes';
import rLensProp from 'ramda/src/lensProp'
import rSet from 'ramda/src/set'

const initState = {
   loading: false,
   showSideNav:false,
}

const loadingProp = rLensProp('loading')
const showSideNav = rLensProp('showSideNav')

export const uiReducer = (state = initState, action) => {
   switch (action.type) {

      case ActionTypes.START_LOADING:
         return rSet(
            loadingProp,
            true,
            state
         )

      case ActionTypes.STOP_LOADING:
         return rSet(
            loadingProp,
            false,
            state
         )
      case ActionTypes.SHOW_SIDE_NAV_BAR:
         return rSet(
            showSideNav,
            true,
            state
         )

      case ActionTypes.HIDE_SIDE_NAV_BAR:
         return rSet(
            showSideNav,
            false,
            state
         )

      default:
         return state
   }
}
export default uiReducer;