import { ActionTypes } from '../ActionTypes';
import rLensProp from 'ramda/src/lensProp';
import rSet from 'ramda/src/set';
import rClone from 'ramda/src/clone';
import _isEmpty from 'lodash/isEmpty';

// eslint-disable-next-line
const initState = {
   server: {
      /*serverIp: 'https://kiwi-fire.nz/',*/
      serverIp: 'https://' + window.location.hostname ,
      serverPort: 0,
      urlREST: 'api/api.php?action=',
   }
}

const serverProps = rLensProp('server')

export const appConfigReducer = (state = initState, action) => {
   switch (action.type) {

      case ActionTypes.ASSIGN_SERVER_CONFIG:
         return rSet(
            serverProps,
            action.serverInfo,
            state
         )

      case ActionTypes.ASSIGN_USER_DEFAULT:
         const { key, value, loginID } = action.payload
         if (_isEmpty(loginID) || _isEmpty(key)) return state

         if (!state.userDefault[loginID]) state.userDefault[loginID] = {} // create if user object not exists

         const userObj = rClone(state.userDefault[loginID])
         userObj[key] = value
         state.userDefault[loginID] = userObj

         return state

      default:
         return state
   }
}
