import { ActionTypes } from '../ActionTypes';
import { EpicTypes } from '../EpicTypes';

//////////////////////////////////////////////////////////
//
//  Assign
//
////////////////////////////////////////////////////////
export const assignNZxDividends = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_NZX_LASEST_DIVI,
      payload
   }
}
export const assignASxDividends = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_ASX_LASEST_DIVI,
      payload
   }
}
export const assignUserYearlyReturnDividends = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_USER_YEARLY_RETURN,
      payload
   }
}
export const assignUserInvestedReturnDividends = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_USER_INVESTED_RETURN,
      payload
   }
}
export const assignUserYearlyNZReturnDividends = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_USER_YEARLY_NZ_RETURN,
      payload
   }
}
export const assignUserYearlyAUReturnDividends = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_USER_YEARLY_AU_RETURN,
      payload
   }
}
export const assignSingleDividendsHistory = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_SINGLE_HISTORY,
      payload
   }
}
export const assignSingleDividendsValueHistory = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_SINGLE_VALUE_HISTORY,
      payload
   }
}
export const assignASDividendsCalendarData = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_AS_CALENDAR_DATA,
      payload
   }
}
export const assignNZDividendsCalendarData = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_NZ_CALENDAR_DATA,
      payload
   }
}

export const assignUpComingNZDividends = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_UP_COMING_NZ,
      payload
   }
}

export const assignUpComingAUDividends = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_UP_COMING_AS,
      payload
   }
}

export const assignUserUpcomingNZDivi = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_UP_COMING_USER_NZ,
      payload
   }
}

export const assignUserUpcomingASDivi = (payload) => {
   return {
      type: ActionTypes.ASSIGN_DIVIDEND_UP_COMING_USER_AS,
      payload
   }
}

//////////////////////////////////////////////////////////
//
//  Fetch
//
////////////////////////////////////////////////////////
export const fetchUserDividendData = () => {
   return {
      type: EpicTypes.FETCH_DIVIDEND_USER_DATA
   }
}
export const fetchDividendUpcommingNZ = () => {
   return {
      type: EpicTypes.FETCH_DIVIDEND_UP_COMMING_NZ
   }
}
export const fetchDividendUserUpcommingNZ = () => {
   return {
      type: EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_NZ
   }
}
export const fetchDividendUpcommingAS = () => {
   return {
      type: EpicTypes.FETCH_DIVIDEND_UP_COMMING_AS
   }
}
export const fetchDividendUserUpcommingAS = () => {
   return {
      type: EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_AS
   }
}
export const fetchSingleDividendsHistory = (code, market) => {
   return {
      type: EpicTypes.FETCH_DIVIDEND_SINGLE_HISTORY,
      code: code,
      market: market
   }
}
export const fetchSingleDividendsValueHistory = (code, market) => {
   return {
      type: EpicTypes.FETCH_DIVIDEND_SINGLE_VALUE_HISTORY,
      code: code,
      market: market
   }
}
export const fetchDividenNZCalendarCodesDates = () => {
   return {
      type: EpicTypes.FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES,
   }
}
export const fetchDividenASCalendarCodesDates = () => {
   return {
      type: EpicTypes.FETCH_DIVIDEND_AS_CALENDAR_CODES_DATES,
   }
}

