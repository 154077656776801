import { ActionTypes } from '../ActionTypes';
import rLensProp from 'ramda/src/lensProp';
import rSet from 'ramda/src/set';
//import rClone from 'ramda/src/clone';
/*import _get from 'lodash/get';
import _set from 'lodash/set';*/

export const calculationsInitState = {
    selectedStockCode: "",
    selectedStockMarket: "NZ",
    selectedStockDividens: [],
}

const selectedStockCodeProp = rLensProp('selectedStockCode');
const selectedStockMarketProp = rLensProp('selectedStockMarket');
const selectedStockDividensProp = rLensProp('selectedStockDividens');

/*
https://levelup.gitconnected.com/react-redux-hooks-useselector-and-usedispatch-f7d8c7f75cdd
*/

const calculationsReducer = (state = calculationsInitState, action) => {
    switch (action.type) {
        case ActionTypes.ASSIGN_CALCULATION_STOCK_CODE_SELECTED:
            return rSet(
                selectedStockCodeProp,
                action.payload,
                state,
            )
        case ActionTypes.ASSIGN_CALCULATION_CALCULATION_MARKET_SELECTED:
            return rSet(
                selectedStockMarketProp,
                action.payload,
                state,
            )
        case ActionTypes.ASSIGN_CALCULATION_CALCULATION_DIVIDENS_SELECTED:
            return rSet(
                selectedStockDividensProp,
                action.payload,
                state,
            )
        case ActionTypes.RESET_REDUX:
            return calculationsInitState;
        default:
            return state
    }
}
export default calculationsReducer;