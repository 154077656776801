import { ActionTypes } from '../ActionTypes';
import { EpicTypes } from '../EpicTypes'

//////////////////////////////////////////////////////////
//
//  Assign
//
////////////////////////////////////////////////////////
export const assignGoalList = (payload) => {
   return {
      type: ActionTypes.ASSIGN_GOAL_LIST,
      payload
   }
}
export const assignNextGoalName = (payload) => {
   return {
      type: ActionTypes.ASSIGN_GOAL_NEXT_NAME,
      payload
   }
}
export const assignNextGoalToGo = (payload) => {
   return {
      type: ActionTypes.ASSIGN_GOAL_NEXT_TOGO,
      payload
   }
}
//////////////////////////////////////////////////////////
//
//  Fetch
//
////////////////////////////////////////////////////////
export const fetchGoalUserList=() =>{
   return {
      type: EpicTypes.FETCH_GOAL_USER_LIST
   }
}

//////////////////////////////////////////////////////////
//
// POST
//
//////////////////////////////////////////////////////////
export const postGoalAdd = (payload,callback) => {
   return {
      type: EpicTypes.POST_GOAL_USER_ADD_GOAL,
      payload,
      callback
   }
}
export const postGoalDelete = (payload,callback) => {
   return {
      type: EpicTypes.POST_GOAL_USER_DELETE_GOAL,
      payload,
      callback
   }
}
export const postGoalEdit = (payload,callback) => {
   return {
      type: EpicTypes.POST_GOAL_USER_EDIT_GOAL,
      payload,
      callback
   }
}

