import rClone from 'ramda/src/clone'
export class LocalStorageUtil {

   static loadState = () => {
      try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) {
          return undefined
        }
        const newState = JSON.parse(serializedState)
        return newState
      } catch (err) {
        return undefined
      }
    }
  
    static saveState = (state) => {
      try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
      } catch (err) {
          // Ignore write errors.
      }
    }
  
    static removeState = () => {
      try {
        localStorage.removeItem('state')
      } catch (err) {
          // Ignore write errors.
      }
    }
  
    static getStateToSave = (state) => {
      const saveState = rClone(state)
      
      saveState.ui.loading = false
      //saveState.login.currentUser = undefined
      return saveState
    }
  }
  