import { EpicTypes } from '../EpicTypes';
import { switchMap, catchError, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { GetObservableFactory } from '../../services/http/GetObservableFactory';
//import { PostObservableFactory } from '../../services/http/PostObservableFactory';
import {
  assignSingleDividendsHistory,
  assignSingleDividendsValueHistory,
  assignUpComingNZDividends,
  assignUpComingAUDividends,
  assignNZDividendsCalendarData,
  assignASDividendsCalendarData,
  assignUserUpcomingNZDivi,
  assignUserUpcomingASDivi
} from '../../store/dividends/dividendActions';
//import { updateCache } from "../website/websiteActions";
import { setIsLoading } from '../../utils/websiteUtil';
import { appStore } from '../../index';
//import { BustCache, ResetCache } from '../ActionTypes';
//import { BustCache } from '../ActionTypes';
import { merge } from 'rxjs';
import notify from 'devextreme/ui/notify';


export const dividendEpics = (action$, store$) => {
  return merge(
    action$.pipe(
      ofType(EpicTypes.FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES),
      switchMap(() => {
        //if (BustCache(EpicTypes.FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES)) {
        setIsLoading(EpicTypes.FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES, true);
        return GetObservableFactory.getNZDiviCalendarCodesDates$()
          .pipe(
            // tap((user) => console.log(`getUser$ result:`, user)),
            map(res => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES, false);
              console.log("getNZDiviCalendarCodesDates return res", res);
              if (!res) {
                const errMsg = `Failed to download NZ dividends calendar data, status: ${res.status}`
                console.error(errMsg)
                notify(errMsg, 'error', 5000)
              } else {
                if (res && Array.isArray(res)) {
                  //appStore.dispatch(updateCache(EpicTypes.FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES));
                  return assignNZDividendsCalendarData(res);
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              console.error(`[ERROR - ${EpicTypes.FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES}]: ${err}`);
              notify('Failed to download NZX dividends calendar data', 'error', 5000);
              setIsLoading(EpicTypes.FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES, false);
              return { type: `empty` };

            }),
          )  // getByUrl$().pipe   
        /* } else {
           return [];
         }*/
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.FETCH_DIVIDEND_AS_CALENDAR_CODES_DATES),
      switchMap(() => {
        //if (BustCache(EpicTypes.FETCH_DIVIDEND_AS_CALENDAR_CODES_DATES)) {
        setIsLoading(EpicTypes.FETCH_DIVIDEND_AS_CALENDAR_CODES_DATES, true);
        return GetObservableFactory.getASDiviCalendarCodesDates$()
          .pipe(
            map(res => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_AS_CALENDAR_CODES_DATES, false);
              if (!res) {
                const errMsg = `Failed to download AS dividends calendar data, status: ${res.status}`
                console.error(errMsg)
                notify(errMsg, 'error', 5000)
              } else {
                if (res && Array.isArray(res)) {
                  //appStore.dispatch(updateCache(EpicTypes.FETCH_DIVIDEND_AS_CALENDAR_CODES_DATES));
                  return assignASDividendsCalendarData(res);
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_AS_CALENDAR_CODES_DATES, false);
              console.error(`[ERROR - ${EpicTypes.FETCH_DIVIDEND_AS_CALENDAR_CODES_DATES}]: ${err}`)
              notify('Failed to download ASX dividends calendar data', 'error', 5000)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
        /*} else {
          return [];
        }*/
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.FETCH_DIVIDEND_UP_COMMING_NZ),
      switchMap(() => {
        //if (BustCache(EpicTypes.FETCH_DIVIDEND_UP_COMMING_NZ)) {
        setIsLoading(EpicTypes.FETCH_DIVIDEND_UP_COMMING_NZ, true);
        return GetObservableFactory.getUpcomingDividendsNZ$()
          .pipe(
            map(res => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_UP_COMMING_NZ, false);
              if (!res) {
                const errMsg = `Failed to download upcomming NZ dividend data, status: ${res.status}`
                console.error(errMsg)
                notify(errMsg, 'error', 5000)
              } else {
                if (res && Array.isArray(res)) {
                  //appStore.dispatch(updateCache(EpicTypes.FETCH_DIVIDEND_UP_COMMING_NZ));
                  return assignUpComingNZDividends(res);
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_UP_COMMING_NZ, false);
              console.error(`[ERROR - ${EpicTypes.FETCH_DIVIDEND_UP_COMMING_NZ}]: ${err}`)
              notify('Failed to download upcomming NZ dividend data', 'error', 5000)
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
        /* } else {
           return [];
         }*/
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_NZ),
      switchMap(() => {
        //if (BustCache(EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_NZ)) {
        setIsLoading(EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_NZ, true);
        return GetObservableFactory.getUserUpcomingNZDivi$()
          .pipe(
            // tap((user) => console.log(`getUser$ result:`, user)),
            map(res => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_NZ, false);
              if (!res) {
                const errMsg = `Failed to download users upcomming NZ dividend data, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              } else {
                if (res && Array.isArray(res)) {
                  //appStore.dispatch(updateCache(EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_NZ)); //Update the cahe
                  return assignUserUpcomingNZDivi(res);
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_NZ, false);
              console.error(`[ERROR - ${EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_NZ}]: ${err}`)
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
        /*} else {
          return [];
        }*/
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.FETCH_DIVIDEND_UP_COMMING_AS),
      switchMap(() => {
        // if (BustCache(EpicTypes.FETCH_DIVIDEND_UP_COMMING_AS)) {
        setIsLoading(EpicTypes.FETCH_DIVIDEND_UP_COMMING_AS, true);
        return GetObservableFactory.getUpcomingDividendsAs$()
          .pipe(
            map(res => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_UP_COMMING_AS, false);
              if (!res) {
                const errMsg = `Failed to download upcomming AS dividend data, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              } else {
                if (res && Array.isArray(res)) {
                  //appStore.dispatch(updateCache(EpicTypes.FETCH_DIVIDEND_UP_COMMING_AS)); //Update the cahe
                  return assignUpComingAUDividends(res);
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_UP_COMMING_AS, false);
              console.error(`[ERROR - ${EpicTypes.FETCH_DIVIDEND_UP_COMMING_AS}]: ${err}`)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
        /*} else {
          return [];
        }*/
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_AS),
      switchMap(() => {
        ///////////////////// get the user stock history for one stock /////////////////////
        //if (BustCache(EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_AS)) {
        setIsLoading(EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_AS, true);
        return GetObservableFactory.getUserUpcomingASDivi$()
          .pipe(
            // tap((user) => console.log(`getUser$ result:`, user)),
            map(res => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_AS, false);
              if (!res) {
                const errMsg = `Failed to download users upcomming AS dividend data, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              } else {
                if (res && Array.isArray(res)) {
                  //appStore.dispatch(updateCache(EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_AS)); //Update the cahe
                  return assignUserUpcomingASDivi(res);
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_AS, false);
              console.error(`[ERROR - ${EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_AS}]: ${err}`)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
        /*} else {
          return [];
        }*/
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.FETCH_DIVIDEND_SINGLE_HISTORY),
      switchMap(({ code, market }) => {
        setIsLoading(EpicTypes.FETCH_DIVIDEND_SINGLE_HISTORY, true);
        appStore.dispatch(assignSingleDividendsHistory([]));
        return GetObservableFactory.getDiviHistoryFor$(code, market)
          .pipe(
            // tap((user) => console.log(`getUser$ result:`, user)),
            map(res => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_SINGLE_HISTORY, false);
              if (!res) {
                const errMsg = `Failed to download dividend history for ` + code + `, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              } else {
                if (res && Array.isArray(res)) {
                  return assignSingleDividendsHistory(res);
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_SINGLE_HISTORY, false);
              console.error(`[ERROR - ${EpicTypes.FETCH_DIVIDEND_SINGLE_HISTORY}]: ${err}`)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.FETCH_DIVIDEND_SINGLE_VALUE_HISTORY),
      switchMap(({ code, market }) => {
        setIsLoading(EpicTypes.FETCH_DIVIDEND_SINGLE_VALUE_HISTORY, true);
        appStore.dispatch(assignSingleDividendsValueHistory([]));
        return GetObservableFactory.getDiviHistoryValueFor$(code, market)
          .pipe(
            // tap((user) => console.log(`getUser$ result:`, user)),
            map(res => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_SINGLE_VALUE_HISTORY, false);
              if (!res) {
                const errMsg = `Failed to download dividend value history for ` + code + `, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              } else {
                if (res && Array.isArray(res)) {
                  return assignSingleDividendsValueHistory(res);
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              setIsLoading(EpicTypes.FETCH_DIVIDEND_SINGLE_VALUE_HISTORY, false);
              console.error(`[ERROR - ${EpicTypes.FETCH_DIVIDEND_SINGLE_VALUE_HISTORY}]: ${err}`)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe
  ) // merge()
}
