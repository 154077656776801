import { EpicTypes } from '../EpicTypes'
//import { GetObservableFactory } from '../../http/GetObservableFactory';
import { merge, concat, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';

//import { assignCurrentUser, logout,fetchFacilities } from '../login/loginActions';
//import { fetchCodeList } from '../codeList/codeListActions';

import {
  fetchStockAnnouncements,
  fetchNzxLatestPrice,
  fetchAsxLatestPrice,
  fetchYoutube,
  fetchTimeFrames,
  fetchAllStockCodeNameMarket,
  fetchCountoryCodes
} from '../website/websiteActions';

//import { appStore } from '../../index';

export const serviceEpic = (action$, store$) => {
  return merge(
    action$.pipe(
      ofType(EpicTypes.FETCH_ALL_DATA_FROM_THE_SERVER),
      switchMap(() => {
        ///////////////////// get the website data /////////////////////
        //[TODO] - remove FETCH_ALL_DATA_FROM_THE_SERVER
        return merge(
          of(fetchStockAnnouncements()),
          of(fetchNzxLatestPrice()),
          of(fetchAsxLatestPrice()),
          of(fetchYoutube()),
          of(fetchTimeFrames()),
          of(fetchAllStockCodeNameMarket()),
          of(fetchCountoryCodes()),
        )
      }),
      catchError((err) => {
        console.error(`[ERROR - ${EpicTypes.FETCH_ALL_DATA_FROM_THE_SERVER}]: ${err}`)
        // withRouter(({history})=>setTimeout(history.push('/home'),2000))
        return;
      }),
      // getByUrl$().pipe
      //)  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.CLEAR_ALL_STATE),
      // tap(() => console.log(`EpicTypes.CLEAR_ALL_STATE`)),
      switchMap(() =>
        concat(
          // of(assignActivitiesForMe([]))
          of({ type: 'empty' })
        )
      )
    )
  )
}
