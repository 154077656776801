import React, { Fragment } from 'react';

import clsx from 'clsx';

import { Paper} from '@material-ui/core';

import { connect } from 'react-redux';

const Footer = props => {
  const { footerFixed } = props;
  return (
    <Fragment>
      <Paper
        square
        className={clsx('app-footer text-black-50', {
          'app-footer--fixed': footerFixed
        })}>
        <div className="app-footer--inner">
          <div className="app-footer--second">
            <span>
            <a
              href="http:\\kiwi-fire.nz"
              rel="noopener"
              title="Kiwi-fire.nz">
              Kiwi-fire.nz
            </a> © 2020 </span>
            
          </div>
        </div>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  footerFixed: state.themeOptions.footerFixed
});
export default connect(mapStateToProps)(Footer);
