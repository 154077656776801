import { ActionTypes } from '../ActionTypes';
import { EpicTypes } from '../EpicTypes';


/////////////////////////////////////////////////////////
//
// Epics
//
/////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////
//
// Post
//
/////////////////////////////////////////////////////////
export const postSignIn = (payload, callBack,errorcallBack) => {
   return {
      type: EpicTypes.POST_USER_SETTINGS_SIGN_IN,
      payload,
      callBack,
      errorcallBack
   }
}
export const postForgotPassword = (payload, callBack) => {
   return {
      type: EpicTypes.POST_USER_SETTINGS_FORGOT_PASSWORD,
      payload,
      callBack,
   }
}
export const postResetPassword = (payload, callBack) => {
   return {
      type: EpicTypes.POST_USER_SETTINGS_RESET_PASSWORD,
      payload,
      callBack,
   }
}
export const postSignUp = (payload, callBack) => {
   return {
      type: EpicTypes.POST_USER_SETTINGS_SIGN_UP,
      payload,
      callBack,
   }
}
export const callSignOut = () => {
   return {
      type: EpicTypes.POST_USER_SETTINGS_SIGN_OUT,
   }
}
export const postUserPortfolioID = (payload) => {
   return {
      type: EpicTypes.POST_USER_SETTINGS_PORTFOLIO_ID,
      payload
   }
}
export const postUserDetailsUpdate = (payload,callBack) => {
   return {
      type: EpicTypes.POST_USER_SETTINGS_DETAILS_UPDATE,
      payload,
      callBack
   }
}
export const postUserDetailsPhotoUpload = (payload,callBack) => {
   return {
      type: EpicTypes.POST_USER_SETTINGS_DETAILS_PHOTO_UPLOAD,
      payload,
      callBack
   }
}
export const postUserAddPortfolio = (payload,callBack) => {
   return {
      type: EpicTypes.POST_USER_ADD_PORTFOLIO,
      payload,
      callBack
   }
}
export const postUserDeletePortfolio = (payload,callBack) => {
   return {
      type: EpicTypes.POST_USER_DELETE_PORTFOLIO,
      payload,
      callBack
   }
}
export const postUserEditPortfolio = (payload,callBack) => {
   return {
      type: EpicTypes.POST_USER_EDIT_PORTFOLIO,
      payload,
      callBack
   }
}

//////////////////////////////////////////////////////////
//
//  Fetch
//
////////////////////////////////////////////////////////
export const fetchUserAccountSettings=() =>{
   return {
      type: EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_DATA
   }
}
export const fetchUserAccountPortfolios=() =>{
   return {
      type: EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS
   }
}

//////////////////////////////////////////////////////////
//
//  Assign
//
////////////////////////////////////////////////////////
export const assignUserAccountSettingsData = (payload) => {
    return {
       type: ActionTypes.ASSIGN_USER_ACCOUNT_SETTINGS_DATA,
       payload
    }
 }
 export const assignUserPortfolioId = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_PORTFOLIO_ID,
      payload
   }
}
export const assignUserPortfolioColor = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_PORTFOLIO_COLOR,
      payload
   }
}
 
 export const assignUserPortfolioSettingsData = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_PORTFOLIO_SETTINGS_DATA,
      payload
   }
}
export const assignUserPortfoliosList = (payload) => {
  return {
     type: ActionTypes.ASSIGN_USER_PORTFOLIOS_LIST,
     payload
  }
}
export const assignUserNotificationsData = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_NOTIFICATIONS_DATA,
      payload
   }
}
