import { ActionTypes } from '../ActionTypes';
import { EpicTypes } from '../EpicTypes';
/////////////////////////////////////////////////////
//
// Assign actions 
//
/////////////////////////////////////////////////////
export const assignCalculationStockCodeSeleced = (payload) => {
   return {
      type: ActionTypes.ASSIGN_CALCULATION_STOCK_CODE_SELECTED,
      payload
   }
}
export const assignCalculationMarketSeleced = (payload) => {
   return {
      type: ActionTypes.ASSIGN_CALCULATION_CALCULATION_MARKET_SELECTED,
      payload
   }
}
export const assignCalculationDividensSeleced = (payload) => {
   return {
      type: ActionTypes.ASSIGN_CALCULATION_CALCULATION_DIVIDENS_SELECTED,
      payload
   }
}


/////////////////////////////////////////////////////
//
// Fetch actions
//
/////////////////////////////////////////////////////
export const fetchCalculationDividensHistory = (code, market) => {
   return {
      type: EpicTypes.FETCH_CALCULATION_DIVIDENS_HISTORY,
      code: code,
      market: market
   }
}
