import { ActionTypes } from '../ActionTypes';
import rLensProp from 'ramda/src/lensProp';
import rSet from 'ramda/src/set';
//import rClone from 'ramda/src/clone';
/*import _get from 'lodash/get';
import _set from 'lodash/set';*/

export const dividendInitState = {
    nzxDividends: [],
    asxDividends: [],
    singleDividendsHistory: [],
    singleDividendsValueHistory: [],
    NZDividendsCalendar: [],
    ASDividendsCalendar: [],
    upComingNZDividends: [],
    upComingAUDividends: [],
    userUpcomingNZDivi: [],
    userUpcomingASDivi: [],
    userYearlyDividendReturn: 0,
    userInvestedDividendReturn: 0,
    userNZYearlyDividendReturn: 0,
    userAUYearlyDividendReturn: 0,
}
const nzxDividendsProp = rLensProp('nzxDividends');
const asxDividendsProp = rLensProp('asxDividends');
const singleDividendsHistoryProp = rLensProp('singleDividendsHistory');
const singleDividendsValueHistoryProp = rLensProp('singleDividendsValueHistory');
const NZDividendsCalendarProp = rLensProp('NZDividendsCalendar');
const ASDividendsCalendarProp = rLensProp('ASDividendsCalendar');
const upComingNZDividendsProp = rLensProp('upComingNZDividends');
const upComingAUDividendsProp = rLensProp('upComingAUDividends');
const userUpcomingNZDiviProp = rLensProp('userUpcomingNZDivi');
const userUpcomingASDiviProp = rLensProp('userUpcomingASDivi');
const userYearlyDividendReturnProp = rLensProp('userYearlyDividendReturn');
const userInvestedDividendReturnProp = rLensProp('userInvestedDividendReturn');
const userNZYearlyDividendReturnProp = rLensProp('userNZYearlyDividendReturn');
const userAUYearlyDividendReturnProp = rLensProp('userAUYearlyDividendReturn');
/*
https://levelup.gitconnected.com/react-redux-hooks-useselector-and-usedispatch-f7d8c7f75cdd
*/

const dividendReducer = (state = dividendInitState, action) => {
    switch (action.type) {
        case ActionTypes.ASSIGN_DIVIDEND_NZX_LASEST_DIVI:
            return rSet(
                nzxDividendsProp,
                action.payload,
                state
            )

        case ActionTypes.ASSIGN_DIVIDEND_ASX_LASEST_DIVI:
            return rSet(
                asxDividendsProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_DIVIDEND_USER_YEARLY_RETURN:
            return rSet(
                userYearlyDividendReturnProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_DIVIDEND_USER_INVESTED_RETURN:
            return rSet(
                userInvestedDividendReturnProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_DIVIDEND_USER_YEARLY_NZ_RETURN:
            return rSet(
                userNZYearlyDividendReturnProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_DIVIDEND_USER_YEARLY_AU_RETURN:
            return rSet(
                userAUYearlyDividendReturnProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_DIVIDEND_SINGLE_HISTORY:
            return rSet(
                singleDividendsHistoryProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_DIVIDEND_SINGLE_VALUE_HISTORY:
            return rSet(
                singleDividendsValueHistoryProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_DIVIDEND_AS_CALENDAR_DATA:
            return rSet(
                ASDividendsCalendarProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_DIVIDEND_NZ_CALENDAR_DATA:
            return rSet(
                NZDividendsCalendarProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_DIVIDEND_UP_COMING_NZ:
            return rSet(
                upComingNZDividendsProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_DIVIDEND_UP_COMING_AS:
            return rSet(
                upComingAUDividendsProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_DIVIDEND_UP_COMING_USER_NZ:
            return rSet(
                userUpcomingNZDiviProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_DIVIDEND_UP_COMING_USER_AS:
            return rSet(
                userUpcomingASDiviProp,
                action.payload,
                state
            )
        case ActionTypes.RESET_REDUX:
            return dividendInitState;

        default:
            return state;
    }
}

export default dividendReducer;