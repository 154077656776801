import { ActionTypes } from '../ActionTypes';
import rLensProp from 'ramda/src/lensProp';
import rSet from 'ramda/src/set';
//import rClone from 'ramda/src/clone';
/*import _get from 'lodash/get';
import _set from 'lodash/set';*/


export const reportInitState = {
    selectedStock: {},
    userHistoryStock: [],
}

const selectedStockProp = rLensProp('selectedStock');
const userHistoryStockProp = rLensProp('userHistoryStock');


/*
https://levelup.gitconnected.com/react-redux-hooks-useselector-and-usedispatch-f7d8c7f75cdd
*/

const repotsReducer = (state = reportInitState, action) => {
    switch (action.type) {
        case ActionTypes.ASSIGN_REPORT_STOCK_SELECTED:
            return rSet(
                selectedStockProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_REPORT_USER_HISTORY_FOR_SELECTED_STOCK:
            return rSet(
                userHistoryStockProp,
                action.payload,
                state
            )
        case ActionTypes.RESET_REDUX:
            return reportInitState;
        default:
            return state
    }
}
export default repotsReducer;