import { ActionTypes } from '../ActionTypes';
import { EpicTypes } from '../EpicTypes';

//////////////////////////////////////////////////////////
//
//  Assign
//
////////////////////////////////////////////////////////
export const assignIsLoading = (key, value) => {
  return {
    type: ActionTypes.ASSIGN_IS_LOADING,
    key,
    value
  }
}
export const assignYoutube = (payload) => {
  return {
    type: ActionTypes.ASSIGN_WEBSITE_YOUTUBE_CONTEN,
    payload
  }
}
export const assignTitle = (payload) => {
  return {
    type: ActionTypes.ASSIGN_WEBSITE_TITLE,
    payload
  }
}
export const assignIsAuthenticated = (data) => {
  return {
    type: ActionTypes.ASSIGN_WEBSITE_IS_AUTHENTICATED,
    payload: data
  }
};
export const assignTimeFrames = (data) => {
  return {
    type: ActionTypes.ASSIGN_WEBSITE_TIME_FRAMES,
    payload: data
  }
};
export const assignCountoryCodes = (data) => {
  return {
    type: ActionTypes.ASSIGN_WEBSITE_COUNTORY_CODES,
    payload: data
  }
};
export const assignNzxLatestPrice = (data) => {
  return {
    type: ActionTypes.ASSIGN_WEBSITE_NZX_LATEST_PRICE,
    payload: data
  }
};
export const assignAsxLatestPrice = (data) => {
  return {
    type: ActionTypes.ASSIGN_WEBSITE_ASX_LATEST_PRICE,
    payload: data
  }
};
export const assignAllStockCodeNameMarket = (data) => {
  return {
    type: ActionTypes.ASSIGN_WEBSITE_SET_ALL_STOCK_CODE_NAME_MARKET,
    payload: data
  }
};
export const assignStockAnnouncements = (data) => {
  return {
    type: ActionTypes.ASSIGN_WEBSITE_SET_STOCK_ANNOUNCEMENTS,
    payload: data
  }
};

//////////////////////////////////////////////////////////
//
//  Fetch
//
////////////////////////////////////////////////////////
export const fetchYoutube = () => {
  return {
    type: EpicTypes.FETCH_WEBSITE_YOUTUBE_CONTEN,
  }
}
export const fetchIsAuthenticated = () => {
  return {
    type: EpicTypes.FETCH_WEBSITE_IS_AUTHENTICATED,
  }
}

export const fetchTimeFrames = () => {
  return {
    type: EpicTypes.FETCH_WEBSITE_TIME_FRAMES,
  }
}
export const fetchCountoryCodes = () => {
  return {
    type: EpicTypes.FETCH_WEBSITE_COUNTORY_CODES,
  }
}
export const fetchNzxLatestPrice = () => {
  return {
    type: EpicTypes.FETCH_WEBSITE_NZX_LATEST_PRICE,
  }
}

export const fetchAsxLatestPrice = () => {
  return {
    type: EpicTypes.FETCH_WEBSITE_ASX_LATEST_PRICE,
  }
}

export const fetchAllStockCodeNameMarket = () => {
  return {
    type: EpicTypes.FETCH_WEBSITE_SET_ALL_STOCK_CODE_NAME_MARKET,
  }
}

export const fetchStockAnnouncements = (code, market) => {
  return {
    type: EpicTypes.FETCH_WEBSITE_SET_STOCK_ANNOUNCEMENTS,
    code: code,
    market: market
  }
}

//////////////////////////////////////////////////////////
//
//  Cache
//
////////////////////////////////////////////////////////
export const updateCache = (value) => {
  return {
    type: ActionTypes.UPDATE_CACHE,
    key: value
  }
}
export const resetCache = (value) => {
  return {
    type: ActionTypes.RESET_CACHE,
    key: value
  }
}