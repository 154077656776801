import React, { Fragment } from 'react';

import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@material-ui/core';
import { useSelector } from 'react-redux'

import { connect } from 'react-redux';

import SidebarHeader from '../../layout-components/SidebarHeader';
import SidebarMenu from '../../layout-components/SidebarMenu';

import accountItems from './accountItems';
import adminNavItems from './adminNavItems';
import navItems from './navItems';
import userNavItems from './userNavItems';
import { appStore } from '../../index';
import { resetStore } from '../../store/app/appActions'


//import { setSidebarToggleMobile } from '../../reducers/themeOptions';
import { setSidebarToggleMobile } from '../../store/ThemeOptions/ThemeOptions';

const Sidebar = props => {
  const {
    setSidebarToggleMobile,
    sidebarToggleMobile,
    sidebarFixed,
    sidebarShadow
  } = props;

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);
  const userType = useSelector(state => state.userSettings.userAccountSettings['userType']);
  const isAuthentication = useSelector(state => state.website['isUserAuthentication']);

  const allNavItems = makeNav(isAuthentication, userType);

  const sidebarMenuContent = (
    <div>
      {window.location.host === 'dev.kiwi-fire.nz' ? <span style={{ margin: '5px',cursor: 'pointer' }} onClick={e => { appStore.dispatch(resetStore()) }}>Reset Store</span> : <></>}
      {allNavItems.map(list => (
        <SidebarMenu
          component="div"
          key={list.label}
          pages={list.content}
          title={list.label}
        />
      ))}

    </div>
  );

  ///////////////////////////////////////////////////
  //
  // Fuctions
  //
  ///////////////////////////////////////////////////
  function makeNav(isAuthentication, userType) {
    let allNavItems = [];
    if (isAuthentication) {
      if (userType === 3) {
        allNavItems = [...userNavItems];
        allNavItems.push(...navItems);
        allNavItems.push(...adminNavItems);
      } else {
        allNavItems = [...userNavItems];
        allNavItems.push(...navItems);
      }
      return allNavItems;
    }
    allNavItems = [...navItems];
    allNavItems.push(...accountItems);
    return allNavItems;
  }
  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <SidebarHeader />
          <PerfectScrollbar>{sidebarMenuContent}</PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-fixed': sidebarFixed
          })}
          square
          elevation={sidebarShadow ? 11 : 3}>
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed
            })}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {sidebarMenuContent}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarFixed: state.themeOptions.sidebarFixed,
  headerFixed: state.themeOptions.headerFixed,
  sidebarToggleMobile: state.themeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
