import { merge } from 'rxjs';
import { EpicTypes } from '../EpicTypes'
import { switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { stopLoading } from '../ui/uiActions';

export const uiEpic = (action$, state) => {
   return merge(

      /////////////////////// VCare Login started ////////////////////////////////
      action$.pipe(
         ofType(EpicTypes.UI_EPIC_STOP_LOADING),
         //tap((action) => console.log(`EpicTypes: ${action.type}`)),
         // ignoreElements()
         switchMap((action) => {
            // console.log(`EpicTypes: ${action.type}`)
            return stopLoading()
         }) // switchMap()
      ) // action$.pipe()
   ) // merge()
}
