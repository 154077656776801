import React, { Fragment } from 'react';
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "react-router-dom";
//import { assignUserAccountSettingsData, callSignOut, postUserPortfolioID,assignUserPortfolioColor,assignUserPortfolioId } from '../store/userSetting/userSettingActions';
import { callSignOut } from '../../store/userSetting/userSettingActions';
import { appStore } from '../../index';

import {
  Avatar,
  Box,
  Menu,
  Button,
  List,
  ListItem,
  Tooltip,
  Divider
} from '@material-ui/core';

export default function HeaderUserbox() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const intialAccountData = useSelector(state => state.userSettings['userAccountSettings']);
  const isLoggedIn = useSelector(state => state.website['isUserAuthentication']);
  const history = useHistory();
  const [image, setImage] = React.useState(intialAccountData.image);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /////////////////////////////////////////////////////////
  //
  // userEffects
  //
  ////////////////////////////////////////////////////////
  React.useEffect(() => {
    if (intialAccountData && intialAccountData.image) {
      setImage(intialAccountData.image);
    }
  }, [intialAccountData]);

  ////////////////////////////////////////////////////////
  //
  // Functions
  //
  ///////////////////////////////////////////////////////
  function handleLogout({ target: { value } }) {
    appStore.dispatch(callSignOut());
  }
  function handleMoveToUserDetails({ target: { value } }) {
    history.push("/UserDetails")
  }
  function makeAvatar() {
    if (isLoggedIn) {
      return (makeUserAvatart());
    } else {
      return (makeVisitorAvatar());
    }
  }

  function makeVisitorAvatar() {
    return (<><IconButton color="inherit" component={Link} to="/SignIn">
      <AccountCircleIcon />
    </IconButton></>);
  }
  function makeUserAvatart() {
    return (<>
      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <Box>
          <Avatar sizes="44" alt="User Avatar" src={"/img/avatars/" + image} />
        </Box>
        <div className="d-none d-xl-block pl-3">
          <div className="font-weight-bold pt-2 line-height-1">{intialAccountData.userName}</div>
          <span className="text-white-50">{/*[TODO]-put somthing here */}</span>
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <Avatar sizes="44" alt="User Avatar" src={"/img/avatars/" + image} />
            </Box>
            <div className="pl-3  pr-3">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {intialAccountData.userName}
              </div>
              <span className="text-black-50 text-center">
                
              </span>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem button={true} onClick={handleMoveToUserDetails}>My Account</ListItem>
            <ListItem button={true} onClick={handleLogout}>Logout</ListItem>
            <Divider className="w-100" />
            <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
              <Tooltip arrow title="Twitter">
                <Button color="default" className="text-twitter">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                  </span>
                </Button>
              </Tooltip>
            </ListItem>
          </List>
        </div>
      </Menu>
    </>);
  }

  return (
    <Fragment>
      {makeAvatar()}
    </Fragment>
  );
}
