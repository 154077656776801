import { ActionTypes } from '../ActionTypes'

export const resetStore = () => {
   return {
      type: ActionTypes.RESET_STORE,
   }
}
export const resetRedux = () => {
   return {
      type: ActionTypes.RESET_REDUX,
   }
}
export const resetUserRedux = () => {
   return {
      type: ActionTypes.RESET_USER_REDUX,
   }
}
