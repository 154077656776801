
export const nzBackgroundColor = "#f1f8ff";
export const nzTextColor = "#000";

export const ozBackgroundColor = "#e6ffed";
export const ozTextColor = "#000";

export const bothBackgroundColor = "#FFFFE0";
export const bothTextColor = "#000";

export const DEFAULT_APP_BAR_COLOR = "#3f51b5";
export const DEFAULT_APP_TEXT_COLOR = "#FFF";

export const RECENT_STOCK_ACTIONS_COLOR = '#e6ccff';

