import { ActionTypes } from '../ActionTypes';
import rLensProp from 'ramda/src/lensProp';
import rSet from 'ramda/src/set';
//import rClone from 'ramda/src/clone';
/*import _get from 'lodash/get';
import _set from 'lodash/set';*/
import { DEFAULT_APP_BAR_COLOR } from '../../components/themes/colors';
import { defultEmpty } from '../../utils/utilsEmptys';

export const userAccountSettingsState = {
    userAccountSettings: defultEmpty(),
    userPortfolioSettings: [],
    userNotification: [],
    userPortfolioList: [],
    portfolioId: 0,
    portfolioColor: DEFAULT_APP_BAR_COLOR
}

const userAccountSettingsProp = rLensProp('userAccountSettings');
const userPortfolioSettingsProp = rLensProp('userPortfolioSettings');
const userNotificationProp = rLensProp('userNotification');
const userPortfolioListProp = rLensProp('userPortfolioList');
const portfolioIdProp = rLensProp('portfolioId');
const portfolioColorProp = rLensProp('portfolioColor');

/*
https://levelup.gitconnected.com/react-redux-hooks-useselector-and-usedispatch-f7d8c7f75cdd
*/

const userSettingsReducer = (state = userAccountSettingsState, action) => {
    switch (action.type) {
        case ActionTypes.ASSIGN_USER_PORTFOLIO_COLOR:
            return rSet(
                portfolioColorProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_PORTFOLIO_ID:

            return rSet(
                portfolioIdProp,
                parseInt(action.payload, 10),
                state
            )
        case ActionTypes.ASSIGN_USER_ACCOUNT_SETTINGS_DATA:
            return rSet(
                userAccountSettingsProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_PORTFOLIO_SETTINGS_DATA:
            return rSet(
                userPortfolioSettingsProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_NOTIFICATIONS_DATA:
            return rSet(
                userNotificationProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_PORTFOLIOS_LIST:
            return rSet(
                userPortfolioListProp,
                action.payload,
                state
            )
        case ActionTypes.RESET_REDUX:
            return userAccountSettingsState;
        default:
            return state
    }
}

export default userSettingsReducer;