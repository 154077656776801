import { EpicTypes } from '../EpicTypes';
import { switchMap, catchError, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { GetObservableFactory } from '../../services/http/GetObservableFactory';
import { PostObservableFactory } from '../../services/http/PostObservableFactory';
import { assignGoalList } from './goalsActions';
import { setIsLoading } from '../../utils/websiteUtil';
/*import { BustCache, ResetCache } from '../ActionTypes';
import { updateCache } from "../website/websiteActions";*/

//import { merge, of, concat, throwError, empty } from 'rxjs';
import { merge, throwError } from 'rxjs';
//import notify from 'devextreme/ui/notify';

//import rClone from 'ramda/src/clone'

import { appStore } from '../../index';

export const goalsEpics = (action$, store$) => {
  return merge(
    action$.pipe(
      ofType(EpicTypes.FETCH_GOAL_USER_LIST),
      switchMap(() => {
       // if (BustCache(EpicTypes.FETCH_GOAL_USER_LIST)) {
          setIsLoading(EpicTypes.FETCH_GOAL_USER_LIST, true);
          return GetObservableFactory.getUserGoals$()
            .pipe(
              // tap(res => console.log(`getCalendarEntryAppointments result:`, res)),
              map(res => {
                if (res) {
                  if (Array.isArray(res)) {
                    res = res.sort((a, b) => (parseFloat(a.updatedAmount) > parseFloat(b.updatedAmount)) ? 1 : ((parseFloat(b.updatedAmount) > parseFloat(a.updatedAmount)) ? -1 : 0));
                    // success case
                    //appStore.dispatch(updateCache(EpicTypes.FETCH_GOAL_USER_LIST)); //Update the cahe
                    setIsLoading(EpicTypes.FETCH_GOAL_USER_LIST, false);
                    return assignGoalList(res);
                  } 
                }
                return { type: `empty` };
              }),
              catchError((err) => {
                setIsLoading(EpicTypes.FETCH_GOAL_USER_LIST, false);
                const errMsg = `${EpicTypes.FETCH_GOAL_USER_LIST}: ${err}`
                console.error(errMsg)
                appStore.dispatch(assignGoalList([]))
                //addToast(errMsg, { appearance: 'error' });
                //if (err.status === 401) return of(assignLoggedIn(false))
                return throwError(err);
              }),
            )  // getUserGoals$().pipe
        /*} else {
          return [];
        }*/
      })  // switchMap
    ), //action$.pipe
    ///// Add Goal
    action$.pipe(
      ofType(EpicTypes.POST_GOAL_USER_ADD_GOAL),
      switchMap(({ payload, callback }) => {
        ////ResetCache(EpicTypes.FETCH_GOAL_USER_LIST);
        return PostObservableFactory.postAddgoal$(payload)
          .pipe(
            // tap((user) => console.log(`getUser$ result:`, user)),
            switchMap(res => {
              if (res.status !== 200) {
                const errMsg = `Failed to add goal, status: ${res.status}`
                console.error(errMsg)
                callback && callback({ hasError: true, message: errMsg });
                //notify(errMsg, 'error', 5000)
              } else {
                const response = res.response;
                if (response && response.success === true) {
                  callback && callback({ hasError: false });
                  /// [TODO] - update the local copy with the goal
                  return { type: `empty` };
                } else {
                  callback && callback({ hasError: true, message: "Failed to add goal" });
                  //notify(`Save measurement failed. ${msg}`, 'error', 5000)
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              console.error(`[ERROR - ${EpicTypes.POST_GOAL_USER_ADD_GOAL}]: ${err}`)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe
    ///// delete goal
    action$.pipe(
      ofType(EpicTypes.POST_GOAL_USER_DELETE_GOAL),
      switchMap(({ payload, callback }) => {
        //ResetCache(EpicTypes.FETCH_GOAL_USER_LIST);
        return PostObservableFactory.postDeleteGoal$(payload)
          .pipe(
            // tap((user) => console.log(`getUser$ result:`, user)),
            switchMap(res => {
              if (res.status !== 200) {
                const errMsg = `Failed to delete goal, status: ${res.status}`
                console.error(errMsg)
                callback && callback({ hasError: true, message: errMsg });
                //notify(errMsg, 'error', 5000)
              } else {
                const response = res.response;
                if (response && response.success === true) {
                  callback && callback({ hasError: false });
                  //[TODO] - deleted goal from loacl
                  return { type: `empty` };
                } else {
                  callback && callback({ hasError: true, message: "Failed to delete goal" });
                  //notify(`Save measurement failed. ${msg}`, 'error', 5000)
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              console.error(`[ERROR - ${EpicTypes.POST_GOAL_USER_DELETE_GOAL}]: ${err}`)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe
    ///// Edit Goal
    action$.pipe(
      ofType(EpicTypes.POST_GOAL_USER_EDIT_GOAL),
      switchMap(({ payload, callback }) => {
        //ResetCache(EpicTypes.FETCH_GOAL_USER_LIST);
        return PostObservableFactory.postEditGoal$(payload)
          .pipe(
            // tap((user) => console.log(`getUser$ result:`, user)),
            switchMap(res => {
              if (res.status !== 200) {
                const errMsg = `Failed to update goal, status: ${res.status}`
                console.error(errMsg)
                callback && callback({ hasError: true, message: errMsg });
                //notify(errMsg, 'error', 5000)
              } else {
                const response = res.response;
                if (response && response.success === true) {
                  callback && callback({ hasError: false });
                  //[TODO] - update the edited 
                  return { type: `empty` };
                } else {
                  callback && callback({ hasError: true, message: "Failed to update goal" });
                  //notify(`Save measurement failed. ${msg}`, 'error', 5000)
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              console.error(`[ERROR - ${EpicTypes.POST_GOAL_USER_EDIT_GOAL}]: ${err}`)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe

  ) // merge()
}
