import { EpicTypes } from '../EpicTypes';
import { switchMap, catchError, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { GetObservableFactory } from '../../services/http/GetObservableFactory';
import {
  assignCryptoUsersData,
  assignCryptoMetaData,
  assignCryptoList,
} from '../../store/crypto/cryptoActions';
import { setIsLoading } from '../../utils/websiteUtil';
// import { appStore } from '../../index';
import { merge } from 'rxjs';
import notify from 'devextreme/ui/notify';


export const cryptoEpics = (action$, store$) => {
  return merge(
    action$.pipe(
      ofType(EpicTypes.FETCH_CRYPTO_USER_LIST),
      switchMap(() => {
        //if (BustCache(EpicTypes.FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES)) {
        setIsLoading(EpicTypes.FETCH_CRYPTO_USER_LIST, true);
        return GetObservableFactory.getUserCrypto$()
          .pipe(
            // tap((user) => console.log(`getUser$ result:`, user)),
            map(res => {
              setIsLoading(EpicTypes.FETCH_CRYPTO_USER_LIST, false);
              if (!res) {
                const errMsg = `Failed to download user crpto list, status: ${res.status}`
                console.error(errMsg)
                notify(errMsg, 'error', 5000)
              } else {
                if (res && Array.isArray(res)) {
                  //appStore.dispatch(updateCache(EpicTypes.FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES));
                  return assignCryptoUsersData(res);
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              console.error(`[ERROR - ${EpicTypes.FETCH_CRYPTO_USER_LIST}]: ${err}`);
              notify('Failed to download user crpto list data', 'error', 5000);
              setIsLoading(EpicTypes.FETCH_CRYPTO_USER_LIST, false);
              return { type: `empty` };

            }),
          )  // getByUrl$().pipe   
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.FETCH_CRYPTO_META_DATA),
      switchMap(() => {
        //if (BustCache(EpicTypes.FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES)) {
        setIsLoading(EpicTypes.FETCH_CRYPTO_META_DATA, true);
        return GetObservableFactory.getCryptoMetaData$()
          .pipe(
            // tap((user) => console.log(`getUser$ result:`, user)),
            map(res => {
              setIsLoading(EpicTypes.FETCH_CRYPTO_META_DATA, false);
              if (!res) {
                const errMsg = `Failed to download crpto metadata, status: ${res.status}`
                console.error(errMsg)
                notify(errMsg, 'error', 5000)
              } else {
                if (res && Array.isArray(res)) {
                  //appStore.dispatch(updateCache(EpicTypes.FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES));
                  return assignCryptoMetaData(res);
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              console.error(`[ERROR - ${EpicTypes.FETCH_CRYPTO_META_DATA}]: ${err}`);
              notify('Failed to download crpto meta data', 'error', 5000);
              setIsLoading(EpicTypes.FETCH_CRYPTO_META_DATA, false);
              return { type: `empty` };

            }),
          )  // getByUrl$().pipe   
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.FETCH_CRYPTO_QUOTE_DATA),
      switchMap(() => {
        setIsLoading(EpicTypes.FETCH_CRYPTO_QUOTE_DATA, true);
        return GetObservableFactory.getCryptoQuoteData$()
          .pipe(
            map(res => {
              setIsLoading(EpicTypes.FETCH_CRYPTO_QUOTE_DATA, false);
              if (!res) {
                const errMsg = `Failed to download crpto metadata, status: ${res.status}`
                console.error(errMsg)
                notify(errMsg, 'error', 5000)
              } else {
                console.log("EpicTypes.FETCH_CRYPTO_QUOTE_DATA res:",res)
                return assignCryptoList(res?.data ? res.data : null);
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              console.error(`[ERROR - ${EpicTypes.FETCH_CRYPTO_QUOTE_DATA}]: ${err}`);
              notify('Failed to download crpto quote data', 'error', 5000);
              setIsLoading(EpicTypes.FETCH_CRYPTO_QUOTE_DATA, false);
              return { type: `empty` };

            }),
          )  // getByUrl$().pipe   
      })  // switchMap
    ), //action$.pipe
  ) // merge()
}
