import { createStore, applyMiddleware, compose } from 'redux'
import { rootReducer } from '../store/app/appReducers';
import { createEpicMiddleware } from 'redux-observable';
import { appEpics } from '../store/app/appEpics';

import { LocalStorageUtil } from './LocalStorageUtil';

const epicMiddleware = createEpicMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export const configureStore = () => {
  const persistedState = LocalStorageUtil.loadState()

  // If the user logged in more than 15 mins
  // if (persistedState && persistedState.timeLoggedIn) {
  //    if (Date.now() - persistedState.timeLoggedIn > 15 * 60 * 60 * 1000) {
  //       persistedState.user.currentUser = {}
  //    }
  // }

  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(
      applyMiddleware(epicMiddleware)
    )
  )

  epicMiddleware.run(appEpics);

  store.subscribe(
    () => {
      //if (!store.getState().login.loggedIn) {
      // 7Apr2020 - do not remove local state when logged out, always keep local state  
      //   LocalStorageUtil.removeState()  // logged out, remove state
      //} else {
        LocalStorageUtil.saveState(store.getState())
      //}
    }
  )

  return store;
}
