import { EpicTypes } from '../EpicTypes';
import { switchMap, catchError, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { PostObservableFactory } from '../../services/http/PostObservableFactory';
import { GetObservableFactory } from '../../services/http/GetObservableFactory';
import {
   assignUserAccountSettingsData, assignUserPortfolioColor,
   assignUserPortfolioId, assignUserPortfoliosList, fetchUserAccountPortfolios,
   //fetchUserAccountSettings, fetchUserAccountPortfolios,
} from '../userSetting/userSettingActions';
import { setIsLoading } from '../../utils/websiteUtil';
import { resetUserRedux } from '../app/appActions';
//import { BustCache, ResetCache, ResetUserCache } from '../ActionTypes';
//import { updateCache } from "../website/websiteActions";

//import { assignUserStockInvestedValue, fetchUserStocksActions } from '../../store/userStocks/userStocksActions';
//import { fetchGoalUserList, assignGoalList, assignNextGoalName, assignNextGoalToGo } from '../goals/goalsActions';
//import { fetchGoalUserList } from '../goals/goalsActions';
/*import {
   assignUserUpcomingASDivi, assignUserUpcomingNZDivi,
   assignUserYearlyAUReturnDividends, assignUserYearlyNZReturnDividends,
   assignUserYearlyReturnDividends,assignUserInvestedReturnDividends
} from '../dividends/dividendActions';*/
//import { fetchUserStockData, fetchAllUserWarrantData } from '../userStocks/userStocksActions';
import { assignIsAuthenticated } from '../website/websiteActions';
//import { useSelector } from 'react-redux';
//import { defultEmpty } from '../../utils/utilsEmptys';
//import { DEFAULT_APP_BAR_COLOR } from '../../components/themes/colors';
//import { assignWaitingList, assignWaitingListDetails, assignProspectsForNewWaitingListDetails } from './waitingListActions';

import { merge, of } from 'rxjs'
//import { merge, of, concat, throwError } from 'rxjs'
//import notify from 'devextreme/ui/notify';
import { appStore } from '../../index';

export const userSettingEpics = (action$, store$) => {
   return merge(
      action$.pipe(
         ofType(EpicTypes.POST_USER_SETTINGS_DETAILS_UPDATE),
         switchMap(({ payload, callBack }) => {
            ///////////////////// Update User details /////////////////////
            return PostObservableFactory.postUpdateUser$(payload)
               .pipe(
                  // tap((user) => console.log(`getUser$ result:`, user)),
                  map(res => {
                     if (res.status !== 200) {
                        const errMsg = `Failed to update users deatils, status: ${res.status}`
                        console.error(errMsg)
                        callBack && callBack({ hasError: true, message: errMsg });
                        //notify(errMsg, 'error', 5000)
                     } else {
                        const response = res.response;
                        if (response && response.success === true) {
                           callBack && callBack({ hasError: false });
                        } else {
                           callBack && callBack({ hasError: true, message: "Failed to update users deatils" });
                           //notify(`Save measurement failed. ${msg}`, 'error', 5000)
                        }
                     }
                     //return merge(of({ type: `empty` }));
                     //ResetCache(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS);
                     return fetchUserAccountPortfolios();
                  }),
                  catchError((err) => {
                     console.error(`[ERROR - ${EpicTypes.POST_USER_SETTINGS_DETAILS_UPDATE}]: ${err}`)
                     // withRouter(({history})=>setTimeout(history.push('/home'),2000))
                     return { type: `empty` };
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe
      action$.pipe(
         ofType(EpicTypes.POST_USER_SETTINGS_DETAILS_PHOTO_UPLOAD),
         switchMap(({ payload, callBack }) => {
            ///////////////////// Update User details /////////////////////
            return PostObservableFactory.postUpdateUserImage$(payload)
               .pipe(
                  // tap((user) => console.log(`getUser$ result:`, user)),
                  map(res => {
                     if (res.status !== 200) {
                        const errMsg = `Failed to update users image, status: ${res.status}`
                        console.error(errMsg)
                        callBack && callBack({ hasError: true, message: errMsg });
                        //notify(errMsg, 'error', 5000)
                     } else {
                        const response = res.response;
                        if (response && response.success === true) {
                           callBack && callBack(response);
                        } else {
                           callBack && callBack({ hasError: true, message: "Failed to update users image", ImageName: null });
                        }
                     }
                     return { type: `empty` };
                  }),
                  catchError((err) => {
                     console.error(`[ERROR - ${EpicTypes.POST_USER_SETTINGS_DETAILS_PHOTO_UPLOAD}]: ${err}`)
                     // withRouter(({history})=>setTimeout(history.push('/home'),2000))
                     return { type: `empty` };
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe

      //getUserPortfolios getUserDetails signin
      action$.pipe(
         ofType(EpicTypes.POST_USER_SETTINGS_SIGN_IN),
         switchMap(({ payload, callBack, errorcallBack }) => {
            ///////////////////// get User /////////////////////
            //appStore.dispatch(resetUserRedux());
            //appStore.dispatch(assignIsAuthenticated(false));
            return PostObservableFactory.postSignin$(payload)
               .pipe(
                  // tap((user) => console.log(`getUser$ result:`, user)),
                  switchMap(res => {
                     if (res.status !== 200) {
                        //const errMsg = `Failed to signin, status: ${res.status}`
                        console.error("Failed to signin, status:", res.status)
                        //notify(errMsg, 'error', 5000)
                     } else {
                        const response = res.response;
                        if (response && response.success === true) {
                           appStore.dispatch(assignIsAuthenticated(true));
                           callBack && callBack({ "hasError": false });
                        } else {
                           appStore.dispatch(assignIsAuthenticated(false));
                           errorcallBack && errorcallBack();
                           //notify(`Save measurement failed. ${msg}`, 'error', 5000)
                        }
                     }
                     return [];
                  }),
                  catchError((err) => {
                     console.error(`[ERROR - ${EpicTypes.POST_USER_SETTINGS_SIGN_IN}]: ${err}`)
                     // withRouter(({history})=>setTimeout(history.push('/home'),2000))
                     return [];
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe

      action$.pipe(
         ofType(EpicTypes.POST_USER_SETTINGS_SIGN_UP),
         switchMap(({ payload, callBack }) => {
            ///////////////////// Update User details /////////////////////
            return PostObservableFactory.postSignUp$(payload)
               .pipe(
                  // tap((user) => console.log(`getUser$ result:`, user)),
                  map(res => {
                     if (res.status !== 200) {
                        const errMsg = `Failed to signup user, status: ${res.status}`
                        console.error(errMsg)
                        callBack && callBack({ hasError: true, message: errMsg });
                        //notify(errMsg, 'error', 5000)
                     } else {
                        const response = res.response;
                        if (response && response?.signup.localeCompare("success") === 0) {
                           appStore.dispatch(assignIsAuthenticated(true));
                           callBack && callBack({ hasError: false });
                        } else {
                           appStore.dispatch(assignIsAuthenticated(false));
                           callBack && callBack({ hasError: true, message: "Failed to signup new user" });
                           //notify(`Save measurement failed. ${msg}`, 'error', 5000)
                        }
                     }
                     return { type: `empty` };
                  }),
                  catchError((err) => {
                     console.error(`[ERROR - ${EpicTypes.POST_USER_SETTINGS_SIGN_UP}]: ${err}`)
                     // withRouter(({history})=>setTimeout(history.push('/home'),2000))
                     return { type: `empty` };
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe
      ///// next action
      action$.pipe(
         ofType(EpicTypes.POST_USER_SETTINGS_RESET_PASSWORD),
         switchMap(({ payload, callBack }) => {
            ///////////////////// requesting a password reset /////////////////////
            return PostObservableFactory.postResetPassword$(payload)
               .pipe(
                  // tap((user) => console.log(`getUser$ result:`, user)),
                  map(res => {
                     if (res.status !== 200) {
                        const errMsg = `Failed to reset password, status: ${res.status}`
                        console.error(errMsg)
                        callBack && callBack(true, errMsg);
                        //notify(errMsg, 'error', 5000)
                     } else {
                        const response = res.response;
                        callBack && callBack(response.error, response.message);
                     }
                     return { type: `empty` };
                  }),
                  catchError((err) => {
                     console.error(`[ERROR - ${EpicTypes.POST_USER_SETTINGS_RESET_PASSWORD}]:`, err)
                     // withRouter(({history})=>setTimeout(history.push('/home'),2000))
                     return { type: `empty` };
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe
      ///// next action
      action$.pipe(
         ofType(EpicTypes.POST_USER_SETTINGS_FORGOT_PASSWORD),
         switchMap(({ payload, callBack }) => {
            ///////////////////// requesting a password reset /////////////////////
            return PostObservableFactory.postForgotPassword$(payload)
               .pipe(
                  // tap((user) => console.log(`getUser$ result:`, user)),
                  map(res => {
                     if (res.status !== 200) {
                        const errMsg = `Failed to reset password, status: ${res.status}`
                        console.error(errMsg)
                        callBack && callBack(true, errMsg);
                        //notify(errMsg, 'error', 5000)
                     } else {
                        const response = res.response;
                        callBack && callBack(response.error, response.message);
                     }
                     return { type: `empty` };
                  }),
                  catchError((err) => {
                     console.error(`[ERROR - ${EpicTypes.POST_USER_SETTINGS_FORGOT_PASSWORD}]:`, err)
                     // withRouter(({history})=>setTimeout(history.push('/home'),2000))
                     return { type: `empty` };
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe
      ///// next action
      action$.pipe(
         ofType(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_DATA),
         switchMap(() => {
            //if (BustCache(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_DATA)) {
            setIsLoading(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_DATA, true);
            return GetObservableFactory.getUserAccountDetails$()
               .pipe(
                  map(response => {
                     setIsLoading(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_DATA, false);
                     if (response) {
                        let updatedResponce = (response.length > 0) ? response[0] : response;
                        updatedResponce.userType = parseInt(updatedResponce.userType, 10);
                        updatedResponce.portfolioId = parseInt(updatedResponce.portfolioId, 10);
                        if (updatedResponce && updatedResponce.portfolioColor && updatedResponce.portfolioId) {
                           //appStore.dispatch(updateCache(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_DATA)); //Update the cahe
                           appStore.dispatch(assignUserAccountSettingsData(updatedResponce));
                           appStore.dispatch(assignUserPortfolioColor(updatedResponce.portfolioColor));
                           appStore.dispatch(assignUserPortfolioId(updatedResponce.portfolioId));
                        }
                     }
                     return { type: `empty` };
                  }),
                  catchError((err) => {
                     setIsLoading(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_DATA, false);
                     const errMsg = `[ERROR - '${EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_DATA}']: ${err}`
                     console.error(errMsg)
                     //notify(errMsg, 'error', 5000)
                     //if (err.status === 401) return of(assignLoggedIn(false))
                     return [];
                  }),
               )  // getByUrl$().pipe
            // } else {
            //   return [];
            // }
         })  // switchMap
      ), //action$.pipe
      action$.pipe(
         ofType(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS),
         switchMap(() => {
            //if (BustCache(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS)) {
            setIsLoading(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS, true);
            return GetObservableFactory.getUserAccountPortfolios$()
               .pipe(
                  map(res => {
                     setIsLoading(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS, false);
                     if (res && Array.isArray(res)) {
                        //appStore.dispatch(updateCache(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS)); //Update the cahe
                        appStore.dispatch(assignUserPortfoliosList(res));

                        /*const selectedPortfolioId = useSelector(state => state.userSettings['portfolioId']);
                        console.log('userSettingEpics - selectedPortfolioId',selectedPortfolioId)
                        if (selectedPortfolioId) {
                           const port = res.find(p => p.id === selectedPortfolioId);
                           console.log('userSettingEpics - port',port)
                           if (port) {
                              console.log('userSettingEpics - assignUserPortfolioColor',port.color)
                              appStore.dispatch(assignUserPortfolioColor(port.color));
                           }
                        }*/

                     }
                     return { type: `empty` };
                  }),
                  catchError((err) => {
                     setIsLoading(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS, false);
                     //ResetCache(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS);
                     appStore.dispatch(assignUserPortfoliosList([]));
                     const errMsg = `[ERROR - '${EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS}']: ${err}`
                     console.error(errMsg)
                     //notify(errMsg, 'error', 5000)
                     //if (err.status === 401) return of(assignLoggedIn(false))
                     return { type: `empty` }
                  }),
               )  // getByUrl$().pipe
            /*} else {
               return [];
            }*/
         })  // switchMap
      ), //action$.pipe
      action$.pipe(
         ofType(EpicTypes.POST_USER_SETTINGS_PORTFOLIO_ID),
         switchMap(({ payload }) => {
            //ResetCache(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_DATA);
            return PostObservableFactory.postUserPortfolioID$(payload)
               .pipe(
                  // tap((user) => console.log(`getUser$ result:`, user)),
                  map(res => {
                     if (res.status !== 200) {
                        const errMsg = `Failed to set portfilo id, status: ${res.status}`
                        console.error(errMsg)
                        //notify(errMsg, 'error', 5000)
                     }
                     return { type: `empty` };
                  }),
                  catchError((err) => {
                     console.error(`[ERROR - ${EpicTypes.POST_USER_SETTINGS_PORTFOLIO_ID}]: ${err}`)
                     // withRouter(({history})=>setTimeout(history.push('/home'),2000))
                     return { type: `empty` };
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe
      action$.pipe(
         ofType(EpicTypes.POST_USER_ADD_PORTFOLIO),
         switchMap(({ payload, callBack }) => {
            return PostObservableFactory.postAddUserPortfolio$(payload)
               .pipe(
                  map(res => {
                     console.log('EpicTypes.POST_USER_ADD_PORTFOLIO return res', res)
                     if (res.status !== 200) {
                        const errMsg = `Failed to add portfilo, status: ${res.status}`
                        console.error(errMsg)
                        callBack && callBack({ hasError: true, message: errMsg });
                        //notify(errMsg, 'error', 5000)
                     } else {
                        //ResetCache(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_DATA);
                        //ResetCache(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS);
                        callBack && callBack({ hasError: false });
                     }
                     return { type: `empty` };
                  }),
                  catchError((err) => {
                     console.error(`[ERROR - ${EpicTypes.POST_USER_ADD_PORTFOLIO}]: ${err}`)
                     return { type: `empty` };
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe
      action$.pipe(
         ofType(EpicTypes.POST_USER_EDIT_PORTFOLIO),
         switchMap(({ payload, callBack }) => {
            ///////////////////// Edit users details /////////////////////
            //ResetCache(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_DATA);
            return PostObservableFactory.postEditPortfolio$(payload)
               .pipe(
                  // tap((user) => console.log(`getUser$ result:`, user)),
                  map(res => {
                     if (res.status !== 200) {
                        const errMsg = `Failed to update portfilo settings, status: ${res.status}`
                        callBack && callBack({ hasError: true, message: errMsg });
                        //notify(errMsg, 'error', 5000)
                     } else {
                        callBack && callBack({ hasError: false });
                     }
                     //ResetCache(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS);
                     return fetchUserAccountPortfolios();
                     //return { type: `empty` };
                  }),
                  catchError((err) => {
                     let errMsg = `[ERROR - ${EpicTypes.POST_USER_EDIT_PORTFOLIO}]: ${err}`
                     console.error(errMsg);
                     callBack && callBack({ hasError: true, message: errMsg });
                     return { type: `empty` };
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe
      action$.pipe(
         ofType(EpicTypes.POST_USER_DELETE_PORTFOLIO),
         switchMap(({ payload, callBack }) => {
            //ResetCache(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_DATA);
            //ResetCache(EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS);
            return PostObservableFactory.postDeletePortfolio$(payload)
               .pipe(
                  map(res => {
                     if (res.status !== 200) {
                        const errMsg = `Failed to delete portfilo, status: ${res.status}`
                        console.error(errMsg)
                        callBack && callBack({ hasError: true, message: errMsg });
                        //notify(errMsg, 'error', 5000)
                     } else {
                        callBack && callBack({ hasError: false });
                     }
                     return { type: `empty` };
                  }),
                  catchError((err) => {
                     console.error(`[ERROR - ${EpicTypes.POST_USER_DELETE_PORTFOLIO}]: ${err}`)
                     // withRouter(({history})=>setTimeout(history.push('/home'),2000))
                     return { type: `empty` };
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
      action$.pipe(
         ofType(EpicTypes.POST_USER_SETTINGS_SIGN_OUT),
         switchMap(() => {
            appStore.dispatch(assignIsAuthenticated(false));
            appStore.dispatch(resetUserRedux());
            window.location.href = '/';
            return GetObservableFactory.callLogout$()
               .pipe(
                  map(res => {
                     return { type: `empty` };
                  }),
                  catchError((err) => {
                     const errMsg = `[ERROR - '${EpicTypes.POST_USER_SETTINGS_SIGN_OUT}']: ${err}`
                     console.error(errMsg)
                     //notify(errMsg, 'error', 5000)
                     //if (err.status === 401) return of(assignLoggedIn(false))
                     return of({ type: `empty` })
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe
   ) // merge()
}
