import { EpicTypes } from '../EpicTypes';
import { switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { GetObservableFactory } from '../../services/http/GetObservableFactory';
//import { PostObservableFactory } from '../../services/http/PostObservableFactory';*/
import { assigUserHistoryForSelecedStock } from './reportsActions';
//import { setIsLoading } from '../../utils/websiteUtil';
//import { merge, of, concat } from 'rxjs';
import { merge } from 'rxjs';
//import notify from 'devextreme/ui/notify';

//import rClone from 'ramda/src/clone'

import { appStore } from '../../index';

export const reportsEpics = (action$, store$) => {
  return merge(
    action$.pipe(
      ofType(EpicTypes.FETCH_REPORT_STOCKS_HISTORY_DATA),
      switchMap(({ code, market }) => {
        appStore.dispatch(assigUserHistoryForSelecedStock([]));
        return GetObservableFactory.getUserStockHistoryFor$(code, market)
          .pipe(
            switchMap(res => {
              if (!res) {
                const errMsg = `Failed to download report stock history for ` + code + `, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              } else {
                if (res && Array.isArray(res)) {
                  appStore.dispatch(assigUserHistoryForSelecedStock(res));
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              console.error(`[ERROR - ${EpicTypes.FETCH_REPORT_STOCKS_HISTORY_DATA}]: ${err}`)
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe
  ) // merge()
}
