import { combineReducers } from 'redux';
import { appConfigReducer } from '../app/appConfigReducer';
import { ActionTypes } from '../ActionTypes';
import calculationsReducer from '../calculations/calculationsReducer';
import cryptoReducer from '../crypto/cryptoReducer';
import dividendReducer from '../dividends/dividendReducer';
import goalsReducer from '../goals/goalsReducer';
import repotsReducer from '../reports/repotsReducer';
import userSettingsReducer from '../userSetting/userSettingReducer';
import userStocksReducer from '../userStocks/userStocksReducer';
import websiteReducer from '../website/websiteReducer';
import uiReducer from '../ui/uiReducer';
import reducer from '../ThemeOptions/ThemeOptions';

export const appReducers = combineReducers({
  appConfig: appConfigReducer,
  calculations: calculationsReducer,
  crypto: cryptoReducer,
  dividend: dividendReducer,
  goals: goalsReducer,
  repots: repotsReducer,
  userSettings: userSettingsReducer,
  userStocks: userStocksReducer,
  website: websiteReducer,
  ui: uiReducer,
  themeOptions: reducer,
  timeLoggedIn: () => null
});

export const rootReducer = (state, action) => {
  switch (action.type) {

    case ActionTypes.RESET_STORE:
      // state = undefined   // force to use init state
      return appReducers(undefined, action)

    default:
      return appReducers(state, action)
  }
}
