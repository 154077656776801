import { ajax } from 'rxjs/ajax';
import { throwError } from 'rxjs';
import { timeout, catchError, map } from 'rxjs/operators';
import { UrlStringBuilder } from './UrlStringBuilder';


export class PostObservableFactory {

   static postToAPI$(url, body) {
      return ajax(
         {
            url: url,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
         }
      ).pipe(
         timeout(30000),
         map((response) => {
            return response
         }),
         catchError(error => {
            if (error.status === 500) {
               console.error(error)
            }
            return throwError(error)
         }),
      )
   }
   static postToMultiPartAPI$(url, formData) {
      return ajax(
         {
            url: url,
            type: 'POST',
            method: 'post',
            body: formData,
            data: formData,
            async: false,
            cache: false,
            dataType: "JSON",
            processData: false,
            contentType: false
         }
      ).pipe(
         timeout(30000),
         map((response) => {
            return response
         }),
         catchError(error => {
            if (error.status === 500) {
               console.error(error)
            }
            return throwError(error)
         }),
      )
   }


   /////////////// entry functions //////////////////

   /*static postActivityPicture$(postData) {
      return PostObservableFactory.postToAPI$(
         UrlStringBuilder.getActivityPictureUrl(),
         postData
      )
   }*/
   static postUserPortfolioID$(postData) {
      return PostObservableFactory.postToAPI$(
         UrlStringBuilder.postUserPortfolioIDUrl(),
         postData
      )
   }
   static postAddNews$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postAddNewsUrl(),
         postData
      )
   }

   static postAddProduct$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postAddProductUrl(),
         postData
      )
   }
   static postAddgoal$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postAddgoalUrl(),
         postData
      )
   }
   static postDeleteGoal$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postDeleteGoalUrl(),
         postData
      )
   }
   static postEditGoal$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postEditGoalUrl(),
         postData
      )
   }
   static postSignin$(postData) {
      return PostObservableFactory.postToAPI$(
         UrlStringBuilder.postSigninUrl(),
         postData
      )
   }
   static postSignUp$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postSignUpUrl(),
         postData
      )
   }
   static postForgotPassword$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postForgotPasswordUrl(),
         postData
      )
   }
   static postResetPassword$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postResetPasswordUrl(),
         postData
      )
   }
   static postAddUserStock$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postAddUserStockUrl(),
         postData
      )
   }
   static postAddWarrant$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postAddWarrantUrl(),
         postData
      )
   }
   static postEditWarrant$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postEditUserWarrantUrl(),
         postData
      )
   }
   static postExcuteWarrantBuy$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postExcuteWarrantBuyUrl(),
         postData
      )
   }
   static postEditUserStock$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postEditUserStockUrl(),
         postData
      )
   }
   static postDeleteUserStock$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postDeleteUserStockUrl(),
         postData
      )
   }
   static postEditUserWarrant$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postEditUserWarrantUrl(),
         postData
      )
   }
   static postDeleteUserWarrant$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postDeleteUserWarrantUrl(),
         postData
      )
   }
   static postUpdateUserImage$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postUpdateUserImageUrl(),
         postData
      )
   }
   static postUpdateUser$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postUpdateUserUrl(),
         postData
      )
   }
   static postDeletePortfolio$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postDeletePortfolioUrl(),
         postData
      )
   }
   static postEditPortfolio$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postEditPortfolioUrl(),
         postData
      )
   }
   static postAddUserPortfolio$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.postAddUserPortfolioUrl(),
         postData
      )
   }
   static postUserCalcDividend$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.setUserCalcDividendUrl(),
         postData
      )
   }
   static postUserStockActions$(postData) {
      return PostObservableFactory.postToMultiPartAPI$(
         UrlStringBuilder.getUserStockActionsUrl(),
         postData
      )
   }
   
   
}