export const adminNavItems = [
    {
        label: 'Admin Actions',
        content: [
            {
                label: "My Crypto",
                description: "",
                to: "/MyCrypto"
            },
            {
                label: "Home Actions",
                icon: "SettingsIcon",
                content: [
                    {
                        label: "Mange News",
                        description: "",
                        to: "/Admin/Home/News"
                    },
                    {
                        label: "Mange Shop",
                        description: "",
                        to: "/Admin/Home/Shop"
                    },
                    {
                        label: "Glossary",
                        description: "",
                        to: "/Admin/Home/Glossary"
                    },
                ]
            }, {
                label: "Admin Dashboard",
                icon: "SettingsIcon",
                content: [
                    {
                        label: "Dashboard",
                        description: "",
                        to: "/Admin/Private/Dashboard"
                    },
                    {
                        label: "Mange Users",
                        description: "",
                        to: "/Admin/Private/Users"
                    },
                ]
            }
        ]
    }
]


export default adminNavItems;