import { ActionTypes } from '../ActionTypes';
import { EpicTypes } from '../EpicTypes';

//////////////////////////////////////////////////////////
//
//  Assign
//
////////////////////////////////////////////////////////
export const assignCryptoCurrentPrice = (payload) => {
   return {
      type: ActionTypes.ASSIGN_CRYPTO_CURRENT_PRICE,
      payload
   }
}
export const assignCryptoList = (payload) => {
   return {
      type: ActionTypes.ASSIGN_CRYPTO_LIST,
      payload
   }
}
export const assignCryptoUsersData = (payload) => {
   return {
      type: ActionTypes.ASSIGN_CRYPTO_USER,
      payload
   }
}
export const assignCryptoMetaData = (payload) => {
   return {
      type: ActionTypes.ASSIGN_CRYPTO_META_DATA,
      payload
   }
}

//////////////////////////////////////////////////////////
//
//  Fetch
//
////////////////////////////////////////////////////////
export const fetchCryptoUserList=() =>{
   return {
      type: EpicTypes.FETCH_CRYPTO_USER_LIST
   }
}
export const fetchCryptoMetaData=() =>{
   return {
      type: EpicTypes.FETCH_CRYPTO_META_DATA
   }
}
export const fetchCryptoQuote=() =>{
   return {
      type: EpicTypes.FETCH_CRYPTO_QUOTE_DATA
   }
}

