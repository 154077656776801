import React from 'react';
import './PortfolioSelect.scss';
import { useSelector } from 'react-redux';
import { appStore } from '../../index';
import NativeSelect from '@material-ui/core/NativeSelect';
import {
    assignUserPortfolioColor,
    assignUserPortfolioId,
    assignUserAccountSettingsData,
    postUserPortfolioID
} from '../../store/userSetting/userSettingActions';
import { resetCache } from '../../store/website/websiteActions';
import { formToObj } from '../../utils/utilsFormate';
import { useStyles } from '../../pages/styles/useStyles';
import { EpicTypes } from '../../store/EpicTypes';

export default function PortfolioSelect(props) {
    const classes = useStyles();
    const selectedPortfolio = useSelector(state => state.userSettings['portfolioId']);
    const PortfoList = useSelector(state => state.userSettings['userPortfolioList']);
    const isAuthenticated = useSelector(state => state.website['isUserAuthentication']);
    const portfolioName = useSelector(state => state.userSettings.userAccountSettings['portfolioName']);
    const accountSettings = useSelector(state => state.userSettings['userAccountSettings']);

    /////////////////////////////////////////////////////
    //
    //    Functions
    //
    ////////////////////////////////////////////////////
    function handleUpdateProfilo({ target: { value } }) {
        if (PortfoList && value) { //no point changing if we do not have a list or the number to set it to
            let selectedPortflio = PortfoList.find((item) => parseInt(item.id, 10) === parseInt(value, 10));
            if (selectedPortflio) {
                ///// Cache bust on port change
                appStore.dispatch(resetCache(EpicTypes.FETCH_USER_STOCKS_ACTIONS));

                ///Update the local settings
                let updatedAccountSettings = { ...accountSettings };
                updatedAccountSettings.portfolioName = selectedPortflio.name;
                updatedAccountSettings.portfolioColor = selectedPortflio.color;
                updatedAccountSettings.portfolioId = parseInt(value, 10);
                appStore.dispatch(assignUserPortfolioColor(updatedAccountSettings.portfolioColor));
                appStore.dispatch(assignUserPortfolioId(updatedAccountSettings.portfolioId));
                appStore.dispatch(assignUserAccountSettingsData(updatedAccountSettings));

                //update the server to remeber the users selected porfilo
                const data = new FormData();
                data.append("id", value);
                appStore.dispatch(postUserPortfolioID(formToObj(data)));
            }
        }
    }

    /////////////////////////////////////////////////////
    //
    //     UI Render
    //
    ////////////////////////////////////////////////////
    const data = [];
    if (PortfoList && Array.isArray(PortfoList)) {
        PortfoList.forEach(val => {
            data.push(<option key={val.id} value={val.id} name={val.name} style={{ color: '#000', background: '#FFF' }}>{val.name}</option>)
        });
    }
    if (isAuthenticated) {
        if (!PortfoList || PortfoList.length < 2) {
            return (<>{portfolioName}</>);
        }
        return (
            <div className="clsPortfolioSelect">
                <NativeSelect
                    onChange={handleUpdateProfilo}
                    value={selectedPortfolio}
                    name="portfolio"
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'portfolio' }}
                    style={{ color: '#FFF', iconOpen: '#FFF', 'font-family': 'Maven Pro' }}
                    onSelect
                >
                    {data}
                </NativeSelect>
            </div>
        );
    } else {
        return (<></>);
    }
}