import { ActionTypes } from '../ActionTypes'

export const startLoading = () => {
  return {
    type: ActionTypes.START_LOADING
  }
}

export const stopLoading = () => {
  return {
    type: ActionTypes.STOP_LOADING
  }
}

export const showSideBarNav = () => {
  return {
    type: ActionTypes.SHOW_SIDE_NAV_BAR
  }
}

export const hideSideBarNav = () => {
  return {
    type: ActionTypes.HIDE_SIDE_NAV_BAR
  }
}
