import { ActionTypes } from '../ActionTypes';
import rLensProp from 'ramda/src/lensProp';
import rSet from 'ramda/src/set';
//import rClone from 'ramda/src/clone';
/*import _get from 'lodash/get';
import _set from 'lodash/set';*/


export const userGoalsState = {
    userGoals: [],
    userGoalsNextGoalName: "No goals set",
    userGoalsNextGoalToGo: 0.00,
}

const userGoalsProp = rLensProp('userGoals');
const userGoalsNextGoalNameProp = rLensProp('userGoalsNextGoalName');
const userGoalsNextGoalToGoProp = rLensProp('userGoalsNextGoalToGo');

/*
https://levelup.gitconnected.com/react-redux-hooks-useselector-and-usedispatch-f7d8c7f75cdd
*/

const goalsReducer = (state = userGoalsState, action) => {
    switch (action.type) {
        case ActionTypes.ASSIGN_GOAL_LIST:
            return rSet(
                userGoalsProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_GOAL_NEXT_NAME:
            return rSet(
                userGoalsNextGoalNameProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_GOAL_NEXT_TOGO:
            return rSet(
                userGoalsNextGoalToGoProp,
                action.payload,
                state
            )
        case ActionTypes.RESET_REDUX:
            return userGoalsState;
        default:
            return state
    }
}

export default goalsReducer;