import { DEFAULT_APP_BAR_COLOR } from '../components/themes/colors';
////////////////////////////////////////////////////////////
// Empty Default Account object
/////////////////////////////////////////////////////////////

export const userTypes = {
    'VISITOR': 0,
    'BASIC_USER': 1,
    'CONTRIBUTOR_USER': 2,
    'ADMIN_USER': 3,
}

export function defultEmpty() {
    return {
        userType: userTypes.VISITOR,
        userName: "Visitor",
        userEmail: "",
        portfolioName: "Default Portfolio",
        portfolioColor: DEFAULT_APP_BAR_COLOR,
        portfolioId: -1,
        image: null,
    }
}