import { combineEpics } from 'redux-observable'

import { calculationsEpics } from '../calculations/calculationsEpics';
import { cryptoEpics } from '../crypto/cryptoEpics';
import { dividendEpics } from '../dividends/dividendEpics';
import { goalsEpics } from '../goals/goalsEpics';
import { reportsEpics } from '../reports/reportsEpics';
import { userSettingEpics } from '../userSetting/userSettingEpics';
import { userStocksEpic } from '../userStocks/userStocksEpic';
import { websiteEpics } from '../website/websiteEpics';
import {serviceEpic} from '../service/serviceEpic';
import {uiEpic} from '../ui/uiEpics';

export const appEpics = combineEpics(
   calculationsEpics,
   cryptoEpics,
   dividendEpics,
   goalsEpics,
   reportsEpics,
   userSettingEpics,
   userStocksEpic,
   websiteEpics,
   serviceEpic,
   uiEpic,

);
