//import { appStore } from '../../index';

export class UrlStringBuilder {

   static getServerName() {
      //[TODO] - reset this for production
      //const server = appStore.getState().appConfig.server;
      let server = {
         //serverIp: 'https://kiwi-fire.nz',
         serverIp: 'https://' + window.location.hostname,
         serverPort: 0,
         urlREST: 'api/api.php?action=',
      };
      if(window.location.hostname.includes("localhost")){
         server.serverIp = 'https://localhost:3000';
      }
      const { serverIp, serverPort, urlREST } = server;
      const urlServerIpPort = serverIp + (serverPort === 0 ? `` : `:` + serverPort);
      const urlServer = `${urlServerIpPort}/${urlREST}`;
      return urlServer
   }
   static getServerNameConcatWith(action, pathVariable = '') {
      let url = UrlStringBuilder.getServerName() + action
      if (pathVariable && pathVariable !== '') {
         url += `/${pathVariable}`
      }
      return url
   }


   /////////////////////////////////////////////////////////
   // Public calls
   ////////////////////////////////////////////////////////

   static getIsauthenticatedUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `isauthenticated`
      )
   }

   static getUserAccountDetailsUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getUserAccountDetails`
      )
   }

   static getUserAccountPortfoliosUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getUserPortfolios`
      )
   }

   static callLogoutUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `logout`
      )
   }

   static postUserPortfolioIDUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `setUserPortfolioJSON`
      )
   }

   static postAddNewsUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `addNews`
      )
   }
   static postAddProductUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `addProduct`
      )
   }
   static getUserUpcomingNZDiviUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getUserUpcomingNZDivi`
      )
   }
   static getUserUpcomingASDiviUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getUserUpcomingASDivi`
      )
   }
   static getAllStockNamesUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getallstockname`
      )
   }
   static getGlossaryUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getGlossary`
      )
   }

   static getUserStockUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getUserStock`
      )
   }
   static getUserPortfoliosUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getUserPortfolios`
      )
   }
   static getNZDiviCalendarCodesDatesUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getNZDiviCalendarCodesDates`
      )
   }
   static getASDiviCalendarCodesDatesUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getASDiviCalendarCodesDates`
      )
   }

   static getOneDayNZDividendsUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getOneDayNZDividends`
      )
   }
   static getOneDayASXDividendsUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getOneDayASXDividends`
      )
   }
   static getYoutubeInfoUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getYoutubeInfo`
      )
   }
   static getProductsUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getProducts`
      )
   }
   static getUpcomingDividendsNZUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getUpcomingDividendsNZ`
      )
   }
   static getUpcomingDividendsAsUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getUpcomingDividendsAU`
      )
   }
   static getUserListUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getUserList`
      )
   }
   static getUserStockActionsUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getUserStockActions`
      )
   }
   static getUserGoalsUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getusergoals`
      )
   }
   static getUserCryptoUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getUserCrypto`
      )
   }
   static getCryptoMetaDataUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getCryptoMetaData`
      )
   }
   static getCryptoQuoteDataUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getCryptoList`
      )
   }
   
   static getUserWarrantsUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getWarrant`
      )
   }
   static getDiviHistoryForUrl(code, market) {
      return UrlStringBuilder.getServerNameConcatWith(
         'getDiviHistoryFor&code=' + code + '&market=' + market
      )
   }
   static getFullStockHistoryForUrl(code, market) {
      return UrlStringBuilder.getServerNameConcatWith(
         'getStockHistoryFor&code=' + code + '&market=' + market
      )
   }
   static getPerformanceForUrl(code, market) {
      return UrlStringBuilder.getServerNameConcatWith(
         'performanceSummaryByCode&code=' + code + '&market=' + market
      )
   }
   static getDiviHistoryValueForUrl(code, market) {
      return UrlStringBuilder.getServerNameConcatWith(
         'getDiviHistoryValueFor&code=' + code + '&market=' + market
      )
   }
   static getDiviHistoryValueByYearsAgoUrl(code, market) {
      return UrlStringBuilder.getServerNameConcatWith(
         'getDiviHistoryValueByYearsAgo&code=' + code + '&market=' + market
      )
   }
   static postAddgoalUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `addgoal`
      )
   }
   static postDeleteGoalUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `deletegoal`
      )
   }
   static postEditGoalUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `editgoal`
      )
   }
   static postSigninUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `signinJSON`
      )
   }
   static getUserDetailsUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getUserDetails`
      )
   }
   static postSignUpUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `signup`
      )
   }
   static postForgotPasswordUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `forgotPassword`
      )
   }
   static postResetPasswordUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `resetPassword`
      )
   }
   static postAddUserStockUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `addUserStock`
      )
   }
   static postAddWarrantUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `addWarrant`
      )
   }
   static postExcuteWarrantBuyUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `excuteWarrantBuy`
      )
   }
   static getUserStockHistoryForUrl(code, market) {
      return UrlStringBuilder.getServerNameConcatWith(
         'getUserStockHistoryFor&code=' + code + '&market=' + market
      )
   }
   static getClosedPriceUrl(selectedCode,market,tradeDate){
      return UrlStringBuilder.getServerNameConcatWith(
         `getPriceOnDateForStock&market=${market}&code=${selectedCode}&date=${tradeDate}`
      )
   }
   static postEditUserStockUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `editUserStock`
      )
   }
   static postDeleteUserStockUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `deleteUserStock`
      )
   }
   static getUserWarrantsHistoryForUrl(code, market) {
      return UrlStringBuilder.getServerNameConcatWith(
         `getUserWarrantsHistoryFor&code=` + code + `&market=` + market
      )
   }
   static postEditUserWarrantUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `editWarrant`
      )
   }
   static postDeleteUserWarrantUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `deleteWarrant`
      )
   }
   static postUpdateUserImageUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `updateUserImage`
      )
   }
   static postUpdateUserUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `updateUser`
      )
   }
   static postDeletePortfolioUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `deletePortfolio`
      )
   }
   static postEditPortfolioUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `editPortfolio`
      )
   }
   static postAddUserPortfolioUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `addUserPortfolio`
      )
   }

   static getTimeFramesUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `timeframes`
      )
   }
   static getCountoryCodesUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `countorycodes`
      )
   }

  /* static getOneDayASXPriceUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getOneDayASXPrice`
      )
   }*/
   static getStockAnnouncementsUrl(code,market) {
      return UrlStringBuilder.getServerNameConcatWith(
         `getNews&code=` + code + `&market=` + market
      )
   }
   static getNzxLatestPriceUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getOneDayNZPrice`
      )
   }
   static getAsxLatestPriceUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `getOneDayASXPrice`
      )
   }
   static setUserCalcDividendUrl() {
      return UrlStringBuilder.getServerNameConcatWith(
         `setUserCalcDividend`
      )
   }
}