
export const navItems = [
  {
    label: 'Navigation menu',
    content: [
      {
        label: "Home",
        description: "",
        to: "/Home"
      },
      {
        label: "DividendInfo",
        description: "",
        to: "/DividendInfo"
      },
      {
        label: "Calculations",
        description: "",
        to: "/Calculations"
      },
      /*{
        label: "Shop",
        description: "",
        to: "/Shop"
      },*/
      {
        label: "Feed Back",
        description: "",
        to: "/FeedBackForm"
      },
      {
        label: "AboutUs",
        description: "",
        to: "/AboutUs"
      },
    ]
  }
]

export default navItems;