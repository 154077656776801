import { ActionTypes } from '../ActionTypes';
import { EpicTypes } from '../EpicTypes';

////////////////////////////////////////////////////////////////////
//
// Assign actions
//
/////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////
// Stocks actions
/////////////////////////////////////////////////////
export const assignUserStocksData = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_STOCKS_VALUE_DATA,
      payload
   }
}
export const assignUserStocksRecentActions = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_STOCKS_RECENT_ACTIONS_DATA,
      payload
   }
}
export const assignUserStocksDashData = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_DASH_STOCKS_DATA,
      payload
   }
}
export const assignUserNZStocksData = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_NZ_STOCKS_DATA,
      payload
   }
}
export const assignUserASStocksData = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_AS_STOCKS_DATA,
      payload
   }
}
export const assignUserStockValue = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_STOCKS_VALUE_DATA,
      payload
   }
}
export const assignUserStockInvestedValue = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_STOCKS_INVESTED_VALUE_DATA,
      payload
   }
}

export const assignUserStockHistory = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_STOCK_HISORY_DATA,
      payload
   }
}
export const assignUserNZStockValue = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_NZ_STOCKS_VALUE_DATA,
      payload
   }
}
export const assignUserAUStockValue = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_AU_STOCKS_VALUE_DATA,
      payload
   }
}

export const assignUserStockNotifcations = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_STOCKS_NOTIFCATIONS,
      payload
   }
}
/////////////////////////////////////////////////////
// Warrant actions
/////////////////////////////////////////////////////

export const assignUserWarrantData = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_WARRANT_DATA,
      payload
   }
}
export const assignUserWarrantHistory = (payload) => {
   return {
      type: ActionTypes.ASSIGN_USER_WARRANT_HISORY_DATA,
      payload
   }
}

//////////////////////////////////////////////////////////
//
//  Fetch
//
////////////////////////////////////////////////////////
export const fetchUserStockData = () => {
   return {
      type: EpicTypes.FETCH_USER_STOCKS_DATA
   }
}
export const fetchUserStockHistoryData = (code, market) => {
   return {
      type: EpicTypes.FETCH_USER_STOCKS_HISTORY_DATA,
      code: code,
      market: market
   }
}
export const fetchAllUserWarrantData = () => {
   return {
      type: EpicTypes.FETCH_ALL_USER_WARRANT_DATA
   }
}
export const fetchWarrantsHistoryFor = (code, market) => {
   return {
      type: EpicTypes.FETCH_WARRANT_HISTORY_DATA_FOR,
      code: code,
      market: market
   }
}
export const fetchUserStocksActions = (portfolioID) => {
   let payload = { "portfolioID": portfolioID }
   return {
      type: EpicTypes.FETCH_USER_STOCKS_ACTIONS,
      payload: payload,
   }
}

//////////////////////////////////////////////////////////
//
//  Post
//
////////////////////////////////////////////////////////
export const postUserStockAdd = (payload, callBack) => {
   return {
      type: EpicTypes.POST_USER_STOCKS_ADD_STOCK,
      payload: payload,
      callBack: callBack
   }
}
export const postUserStockRemove = (payload, callBack) => {
   return {
      type: EpicTypes.POST_USER_STOCKS_REMOVE_STOCK,
      payload: payload,
      callBack: callBack
   }
}
export const postUserStocksEdit = (payload, callBack) => {
   return {
      type: EpicTypes.POST_USER_STOCKS_EDIT_STOCK,
      payload: payload,
      callBack: callBack
   }
}
export const postUserWarrantAdd = (payload, callBack) => {
   return {
      type: EpicTypes.POST_USER_WARRANT_ADD,
      payload: payload,
      callBack: callBack
   }
}
export const postEditUserWarrant = (payload, callBack) => {
   return {
      type: EpicTypes.POST_USER_WARRANT_EDIT,
      payload: payload,
      callBack: callBack
   }
}
export const postUserWarrantExcuteBuy = (payload, callBack) => {
   return {
      type: EpicTypes.POST_USER_WARRANT_EXCUTE_BUY,
      payload: payload,
      callBack: callBack
   }
}
export const postDividendUpdate = (payload, callBack) => {
   return {
      type: EpicTypes.POST_USER_STOCKS_DIVIDEND_UPDATE,
      payload: payload,
      callBack: callBack
   }
}

