import { EpicTypes } from '../EpicTypes';
import { switchMap, catchError, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { GetObservableFactory } from '../../services/http/GetObservableFactory';
//import { PostObservableFactory } from '../../http/PostObservableFactory';
import { assignCalculationDividensSeleced } from './calculationsActions';

//import { merge, of, concat } from 'rxjs';
import { merge } from 'rxjs';
//import notify from 'devextreme/ui/notify';

//import rClone from 'ramda/src/clone'
//import { setIsLoading } from '../../utils/websiteUtil';
import { appStore } from '../../index';

export const calculationsEpics = (action$, store$) => {
  return merge(
    action$.pipe(
      ofType(EpicTypes.FETCH_CALCULATION_DIVIDENS_HISTORY),
      switchMap(({ code, market }) => {
        ///////////////////// get the user stock history for one stock /////////////////////
        appStore.dispatch(assignCalculationDividensSeleced([]));
        return GetObservableFactory.getDiviHistoryValueFor$(code,market)
          .pipe(
            // tap((user) => console.log(`getUser$ result:`, user)),
            map(res => {

              if (!res) {
                const errMsg = `Failed to download dividend history ${code},${market}, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              } else {
                if (res && Array.isArray(res)) {
                  return assignCalculationDividensSeleced(res);
                }
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              console.error(`[ERROR - ${EpicTypes.FETCH_CALCULATION_DIVIDENS_HISTORY}]: ${err}`)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe
  ) // merge()
}
