import { EpicTypes } from '../EpicTypes';
import { switchMap, catchError, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { GetObservableFactory } from '../../services/http/GetObservableFactory';
//import { PostObservableFactory } from '../../http/PostObservableFactory';
import {
   assignStockAnnouncements, assignYoutube,
   assignIsAuthenticated, assignTimeFrames,
   assignCountoryCodes, assignNzxLatestPrice,
   assignAsxLatestPrice, assignAllStockCodeNameMarket,
} from './websiteActions';
import { setIsLoading } from '../../utils/websiteUtil';
//import { useToasts } from 'react-toast-notifications';
import { merge, throwError } from 'rxjs';
//import notify from 'devextreme/ui/notify';
//import { BustCache, ResetCache } from '../ActionTypes';
//import { updateCache } from "../website/websiteActions";


export const websiteEpics = (action$, store$) => {
   //const { addToast } = useToasts();
   //addToast(message, { appearance: 'success' });
   //addToast(errorMessage, { appearance: 'error' });
   return merge(
      action$.pipe(
         ofType(EpicTypes.FETCH_WEBSITE_IS_AUTHENTICATED),
         switchMap(() => {
            return GetObservableFactory.getIsauthenticated$()
               .pipe(
                  // tap(res => console.log(`getCalendarEntryAppointments result:`, res)),
                  map(res => {
                     if (res && res.isAuthenticated === true) {
                        // success case
                        return assignIsAuthenticated(res["isAuthenticated"]);
                     } else {
                        return assignIsAuthenticated(false) // response is empty
                     }
                  }),
                  catchError((err) => {
                     const errMsg = `${EpicTypes.FETCH_WEBSITE_IS_AUTHENTICATED}: ${err}`
                     console.error(errMsg)
                     //addToast(errMsg, { appearance: 'error' });
                     //if (err.status === 401) return of(assignLoggedIn(false))
                     return throwError(err);
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe*/
      action$.pipe(
         ofType(EpicTypes.FETCH_WEBSITE_SET_STOCK_ANNOUNCEMENTS),
         switchMap(({ code, market }) => {
            setIsLoading(EpicTypes.FETCH_WEBSITE_SET_STOCK_ANNOUNCEMENTS, true);
            return GetObservableFactory.getStockAnnouncements$(code, market)
               .pipe(
                  map(res => {
                     setIsLoading(EpicTypes.FETCH_WEBSITE_SET_STOCK_ANNOUNCEMENTS, false);
                     if (res && res.length > 0) {
                        // success case
                        return assignStockAnnouncements(res)
                     }
                     return { type: `empty` }
                  }),
                  catchError((err) => {
                     setIsLoading(EpicTypes.FETCH_WEBSITE_SET_STOCK_ANNOUNCEMENTS, false);
                     const errMsg = `${EpicTypes.FETCH_WEBSITE_SET_STOCK_ANNOUNCEMENTS}: ${err}`
                     console.error(errMsg)
                     //addToast(errMsg, { appearance: 'error' });
                     //if (err.status === 401) return of(assignLoggedIn(false))
                     return { type: `empty` }
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe*/
      action$.pipe(
         ofType(EpicTypes.FETCH_WEBSITE_ASX_LATEST_PRICE),
         switchMap(() => {
            //if (BustCache(EpicTypes.FETCH_WEBSITE_ASX_LATEST_PRICE)) {
               setIsLoading(EpicTypes.FETCH_WEBSITE_ASX_LATEST_PRICE, true);
               return GetObservableFactory.getAsxLatestPrice$()
                  .pipe(
                     // tap(res => console.log(`getCalendarEntryAppointments result:`, res)),
                     map(res => {
                        setIsLoading(EpicTypes.FETCH_WEBSITE_ASX_LATEST_PRICE, false);
                        if (res && res.length > 0) {
                           // success case
                           //appStore.dispatch(updateCache(EpicTypes.FETCH_WEBSITE_ASX_LATEST_PRICE)); //Update the cahe
                           return assignAsxLatestPrice(res);
                        } else {
                           return assignAsxLatestPrice([])
                        }
                     }),
                     catchError((err) => {
                        setIsLoading(EpicTypes.FETCH_WEBSITE_ASX_LATEST_PRICE, false);
                        //appStore.dispatch(ResetCache(EpicTypes.FETCH_WEBSITE_ASX_LATEST_PRICE)); //reset the cahe
                        const errMsg = `${EpicTypes.FETCH_WEBSITE_ASX_LATEST_PRICE}: ${err}`
                        console.error(errMsg)
                        //addToast(errMsg, { appearance: 'error' });
                        //if (err.status === 401) return of(assignLoggedIn(false))
                        return throwError(err);
                     }),
                  )
           // } else {
           //    return [];
           // } // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe*/
      action$.pipe(
         ofType(EpicTypes.FETCH_WEBSITE_NZX_LATEST_PRICE),
         switchMap(() => {
            //if (BustCache(EpicTypes.FETCH_WEBSITE_NZX_LATEST_PRICE)) {
               setIsLoading(EpicTypes.FETCH_WEBSITE_NZX_LATEST_PRICE, true);
               return GetObservableFactory.getNzxLatestPrice$()
                  .pipe(
                     // tap(res => console.log(`getCalendarEntryAppointments result:`, res)),
                     map(res => {
                        setIsLoading(EpicTypes.FETCH_WEBSITE_NZX_LATEST_PRICE, false);
                        if (res && res.length > 0) {
                           // success case
                          // appStore.dispatch(updateCache(EpicTypes.FETCH_WEBSITE_NZX_LATEST_PRICE)); //Update the cahe
                           return assignNzxLatestPrice(res)
                        } else {
                           return assignNzxLatestPrice([])
                        }
                     }),
                     catchError((err) => {
                        //appStore.dispatch(ResetCache(EpicTypes.FETCH_WEBSITE_NZX_LATEST_PRICE)); //reset the cahe
                        setIsLoading(EpicTypes.FETCH_WEBSITE_NZX_LATEST_PRICE, false);
                        const errMsg = `${EpicTypes.FETCH_WEBSITE_NZX_LATEST_PRICE}: ${err}`
                        console.error(errMsg)
                        //addToast(errMsg, { appearance: 'error' });
                        //if (err.status === 401) return of(assignLoggedIn(false))
                        return throwError(err);
                     }),
                  )
            //} else {
            //   return [];
            //} // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe*/
      action$.pipe(
         ofType(EpicTypes.FETCH_WEBSITE_YOUTUBE_CONTEN),
         switchMap(() => {
            return GetObservableFactory.getYoutubeInfo$()
               .pipe(
                  // tap(res => console.log(`getCalendarEntryAppointments result:`, res)),
                  map(res => {
                     if (res && res.length > 0) {
                        // success case
                        return assignYoutube(res)
                     } else {
                        return assignYoutube([]) // response is empty
                     }
                  }),
                  catchError((err) => {
                     const errMsg = `${EpicTypes.FETCH_WEBSITE_YOUTUBE_CONTEN}: ${err}`
                     console.error(errMsg)
                     //addToast(errMsg, { appearance: 'error' });
                     //if (err.status === 401) return of(assignLoggedIn(false))
                     return throwError(err);
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe*/
      action$.pipe(
         ofType(EpicTypes.FETCH_WEBSITE_TIME_FRAMES),
         switchMap(() => {
            return GetObservableFactory.getTimeFrames$()
               .pipe(
                  // tap(res => console.log(`getCalendarEntryAppointments result:`, res)),
                  map(res => {
                     if (res && res.length > 0) {
                        // success case
                        return assignTimeFrames(res)
                     } else {
                        return assignTimeFrames([]) // response is empty
                     }
                  }),
                  catchError((err) => {
                     const errMsg = `${EpicTypes.FETCH_WEBSITE_TIME_FRAMES}: ${err}`
                     console.error(errMsg)
                     //addToast(errMsg, { appearance: 'error' });
                     //if (err.status === 401) return of(assignLoggedIn(false))
                     return throwError(err);
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe*/
      action$.pipe(
         ofType(EpicTypes.FETCH_WEBSITE_SET_ALL_STOCK_CODE_NAME_MARKET),
         switchMap(() => {
            return GetObservableFactory.getAllStockNames$()
               .pipe(
                  // tap(res => console.log(`getCalendarEntryAppointments result:`, res)),
                  map(res => {
                     if (res && res.length > 0) {
                        // success case
                        return assignAllStockCodeNameMarket(res)
                     } else {
                        return assignAllStockCodeNameMarket([]) // response is empty
                     }
                  }),
                  catchError((err) => {
                     const errMsg = `${EpicTypes.FETCH_WEBSITE_SET_ALL_STOCK_CODE_NAME_MARKET}: ${err}`
                     console.error(errMsg)
                     //addToast(errMsg, { appearance: 'error' });
                     //if (err.status === 401) return of(assignLoggedIn(false))
                     return throwError(err);
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe*/
      action$.pipe(
         ofType(EpicTypes.FETCH_WEBSITE_COUNTORY_CODES),
         switchMap(() => {
            return GetObservableFactory.getCountoryCodes$()
               .pipe(
                  // tap(res => console.log(`getCalendarEntryAppointments result:`, res)),
                  map(res => {
                     if (res && res.length > 0) {
                        // success case
                        return assignCountoryCodes(res)
                     } else {
                        return assignCountoryCodes([]) // response is empty
                     }
                  }),
                  catchError((err) => {
                     const errMsg = `${EpicTypes.FETCH_WEBSITE_COUNTORY_CODES}: ${err}`
                     console.error(errMsg)
                     //addToast(errMsg, { appearance: 'error' });
                     //if (err.status === 401) return of(assignLoggedIn(false))
                     return throwError(err);
                  }),
               )  // getByUrl$().pipe
         })  // switchMap
      ), //action$.pipe*/
   ) // merge()
}
