import { appStore } from '../index';
import { assignIsLoading } from '../store/website/websiteActions';

////////////////////////////////////////
// set the loading state for the key with the boolean value 
// @Param string key  
// @Param boolean value
/////////////////////////////////////
export function setIsLoading(key, value) {
    appStore.dispatch(assignIsLoading(key, value));
}
