import { ActionTypes } from '../ActionTypes';
import { EpicTypes } from '../EpicTypes';

////////////////////////////////////////////////////////////////////
//
// Assign actions
//
/////////////////////////////////////////////////////////////////////
export const assignReportStockSeleced = (payload) => {
   return {
      type: ActionTypes.ASSIGN_REPORT_STOCK_SELECTED,
      payload
   }
}
export const assigUserHistoryForSelecedStock = (payload) => {
   return {
      type: ActionTypes.ASSIGN_REPORT_USER_HISTORY_FOR_SELECTED_STOCK,
      payload
   }
}
//////////////////////////////////////////////////////////
//
//  Fetch
//
////////////////////////////////////////////////////////
export const fetchReportUserStockHistoryData = (code, market) => {
   return {
      type: EpicTypes.FETCH_REPORT_STOCKS_HISTORY_DATA,
      code: code,
      market: market
   }
}
