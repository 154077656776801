import React from 'react';
import spinner from '../../assets/images/loading/small_load.gif';

export default function Spinner(props) {
    return (
        <div {...props}>
            <img
                src={spinner}
                style={{ width: '80px', margin: 'auto', display: 'block' }}
                alt="Loading..."
            />
        </div>);
}
