//import { ActionSettingsInputComposite } from "material-ui/svg-icons";

/// expected database date formate
export const DATE_FORMATE = "yyyy/MM/dd";
export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NZD',
});
////////////////////////////////////////////////////////////
//  getDate: convert database string date to dispay date string
//  e.g. 01/05/2020 to 01/may/2020
/////////////////////////////////////////////////////////////
export function getDate(dateStr) {
    try {
        var objDate = new Date(dateStr);
        return objDate.toLocaleString("en", { day: "numeric" }) + '/' +
            objDate.toLocaleString("en", { month: "short" }) + '/' +
            objDate.toLocaleString("en", { year: "numeric" });
    } catch (e) {
        return "err" + dateStr;
    }
}

////////////////////////////////////////////////////////////
// Format a string to a currenacy e.g. 100 = $100.00
// <param numStr>
///////////////////////////////////////////////////////////
export function toCurrency(num) {
    try {
        return formatter.format(num)
    } catch (e) {
        return num;
    }
}

////////////////////////////////////////////////////////////
// Form data to objcet
/////////////////////////////////////////////////////////////
export function formToObj(form) {
    let obj = {};
    for (var pair of form.entries()) {
        obj[pair[0]] = pair[1];
    }
    return obj;
}
///////////////////////////////////////////////////////////////
// Format a string to database string
//////////////////////////////////////////////////////////////
export function toDatabaseDate(dateStr, format = DATE_FORMATE) {
    try {
        var objDate = new Date(dateStr).format(format);
        let dd = objDate.getDate();
        let mm = objDate.getMonth() + 1; //January is 0!
        let yyyy = objDate.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        let date = yyyy + '-' + mm + '-' + dd;
        return date;
    } catch (e) {
        return dateStr;
    }
}