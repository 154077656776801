export const userNavItems = [
  {
    label: 'User Actions',
    content: [
      {
        label: "Dashboard",
        description: "",
        to: "/Dashboard"
      }, {
        label: "User details",
        description: "",
        to: "/UserDetails"
      }, {
        label: "Goals",
        description: "",
        to: "/UserGoals"
      }, {
        label: "My Stocks",
        description: "",
        to: "/MyStocks"
      }, {
        label: "My Dividends",
        description: "",
        to: "/MyDividends"
      },
      {
        label: "Reports",
        description: "",
        content: [
          
          {
            label: "Portfolio Performance",
            description: "",
            to: "/Reports/PortfolioPerformance"
          },
          {
            label: "Stock Overview",
            description: "",
            to: "/Reports/MyStockOverview"
          },
          {
            label: "Stock Performance",
            description: "",
            to: "/Reports/MyStockPerformance"
          },

        ]
      },
    ]
  }
]

export default userNavItems;
