
import React from 'react';
import { fetchIsAuthenticated } from '../../store/website/websiteActions';
import { appStore } from '../../index';
//import { useSelector } from 'react-redux';
/*import {
    fetchNzxLatestPrice,
    fetchAsxLatestPrice,
    fetchAllStockCodeNameMarket,
    fetchStockAnnouncements,
} from '../../store/website/websiteActions';*/


export default function WebsiteCacheCheck(props) {

  ////////////// check the user is authenticated
    appStore.dispatch(fetchIsAuthenticated());

  //const cacheUpdated = useSelector(state => state.website['cacheDateTime']);
  //cacheUpdate();
  ///////////////////////////////////////////
  //
  //  use Effects
  //
  ////////////////////////////////////////////
  /* React.useEffect(() => {
       appStore.dispatch(fetchNzxLatestPrice());
       appStore.dispatch(fetchAsxLatestPrice());
       appStore.dispatch(fetchStockAnnouncements());
       appStore.dispatch(fetchAllStockCodeNameMarket());
   }, [cacheUpdated]);*/

  return (<></>);
}

/*



export const fetchAllStockCodeNameMarket = () => {
  return {
    type: EpicTypes.FETCH_WEBSITE_SET_ALL_STOCK_CODE_NAME_MARKET,
  }
}

export const fetchStockAnnouncements = () => {
  return {
    type: EpicTypes.FETCH_WEBSITE_SET_STOCK_ANNOUNCEMENTS,
  }
}
*/